import React, { useState } from 'react';
import {
    Modal,
    Button,
    Typography,
    Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { doc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import dayjs from 'dayjs';

const AdminEditUser = ({ user, onClose }) => {
    const [formData, setFormData] = useState(user);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSave = async () => {
        try {
            await setDoc(doc(db, 'users', formData.id), formData);
            setSnackbarOpen(true); // Show success message
            onClose();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    return (
        <>
            <Modal open={!!user} onClose={onClose} closeAfterTransition>
                <div className="flex justify-center items-center min-h-screen bg-gray-600 bg-opacity-50">
                    <div className="bg-white rounded-lg p-8 w-full max-w-md shadow-lg transform transition-all duration-300 scale-95 hover:scale-100">
                        <div className="flex justify-between items-center mb-6">
                            <Typography variant="h6" className="text-xl font-semibold text-gray-800">
                                Edit User
                            </Typography>
                            <Button onClick={onClose} color="secondary">
                                <CloseIcon />
                            </Button>
                        </div>
                        {formData && (
                            <>
                                <div className="space-y-1">
                                    {/* Name Field */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-0.5">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            value={formData.name}
                                            onChange={(e) =>
                                                setFormData({ ...formData, name: e.target.value })
                                            }
                                            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
                                        />
                                    </div>

                                    {/* Partner Tag */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-0.5">
                                            Partner Tag
                                        </label>
                                        <input
                                            type="text"
                                            value={formData.PartnerTag}
                                            onChange={(e) =>
                                                setFormData({ ...formData, PartnerTag: e.target.value })
                                            }
                                            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
                                        />
                                    </div>

                                    {/* Email */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-0.5">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            value={formData.email}
                                            disabled
                                            className="w-full p-2 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
                                        />
                                    </div>

                                    {/* Start Date */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-0.5">
                                            Start Date
                                        </label>
                                        <input
                                            type="date"
                                            value={
                                                formData.startDate
                                                    ? dayjs(formData.startDate.toDate()).format('YYYY-MM-DD')
                                                    : ''
                                            }
                                            disabled
                                            className="w-full p-2 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
                                        />
                                    </div>

                                    {/* Created At */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-0.5">
                                            Created At
                                        </label>
                                        <input
                                            type="date"
                                            value={
                                                formData.createdAt
                                                    ? dayjs(formData.createdAt.toDate()).format('YYYY-MM-DD')
                                                    : ''
                                            }
                                            disabled
                                            className="w-full p-2 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
                                        />
                                    </div>

                                    {/* End Date */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-0.5">
                                            End Date
                                        </label>
                                        <input
                                            type="date"
                                            value={
                                                formData.endDate
                                                    ? dayjs(formData.endDate.toDate()).format('YYYY-MM-DD')
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                setFormData({ ...formData, endDate: e.target.value })
                                            }
                                            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
                                        />
                                    </div>

                                    {/* Role */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-0.5">
                                            Role
                                        </label>
                                        <select
                                            value={formData.role}
                                            onChange={(e) =>
                                                setFormData({ ...formData, role: e.target.value })
                                            }
                                            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none"
                                        >
                                            <option value="admin">Admin</option>
                                            <option value="user">User</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Save Button */}
                                <div className="mt-6">
                                    <button
                                        onClick={handleSave}
                                        className="w-full py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="User details updated successfully!"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </>
    );
};

export default AdminEditUser;
