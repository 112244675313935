import React from 'react';
import { Modal, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

const AdminViewUser = ({ user, onClose }) => (
    <Modal open={!!user} onClose={onClose} closeAfterTransition>
        <div className="flex justify-center items-center min-h-screen bg-gray-600 bg-opacity-50">
            <div className="bg-white rounded-lg p-8 w-full max-w-md shadow-lg">
                <div className="flex justify-between items-center mb-6">
                    <Typography variant="h6" className="text-xl font-semibold text-gray-800">View User</Typography>
                    <Button onClick={onClose} color="secondary">
                        <CloseIcon />
                    </Button>
                </div>
                {user && (
                    <div className="space-y-4">
                        <Typography><strong>Name: </strong>{user.name}</Typography>
                        <Typography><strong>Email: </strong>{user.email}</Typography>
                        <Typography><strong>Partner Tag: </strong>{user.PartnerTag? user.PartnerTag : 'Not yet added!!'}</Typography>
                        <Typography><strong>Role: </strong>{user.role}</Typography>
                        <Typography><strong>Links Created: </strong>{user.productIds ? Object.keys(user.productIds).length : 0}</Typography>
                        <Typography><strong>Start Date: </strong>{dayjs(user.startDate.toDate()).format('YYYY-MM-DD')}</Typography>
                        <Typography><strong>End Date: </strong>{dayjs(user.endDate.toDate()).format('YYYY-MM-DD')}</Typography>
                    </div>
                )}
            </div>
        </div>
    </Modal>
);

export default AdminViewUser;
