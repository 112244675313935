import React, { useState, useEffect } from 'react';
import { Typography, Container, Grid, Button } from '@mui/material';
import { getBrandMetrics, getUserMetrics, getFeedbackMetrics, getFeatureSuggestionMetrics, getAccountDeletionRequests } from '../helpers/adminDashboardAPI';
import { useNavigate, Navigate } from 'react-router-dom';
import { isAdminLoggedIn } from '../utils/authUtils';
import '../styles/AdminDashboard.css'; // Custom CSS for hover effect

const AdminDashboard = () => {
  const [brandMetrics, setBrandMetrics] = useState({});
  const [userMetrics, setUserMetrics] = useState({});
  const [feedbackMetrics, setFeedbackMetrics] = useState({});
  const [featureSuggestionMetrics, setFeatureSuggestionMetrics] = useState({});
  const [accountDeletionMetrics, setAccountDeletionMetrics] = useState({});
  const [isAdminLoggedInState, setIsAdminLoggedInState] = useState(null); // State to track admin login status

  const navigate = useNavigate();

  useEffect(() => {
    const adminSession = sessionStorage.getItem('admin');

    // If no admin session, set logged in state to false
    if (!isAdminLoggedIn) {
      setIsAdminLoggedInState(false);
    } else {
      setIsAdminLoggedInState(true);
      const fetchData = async () => {
        try {
          setBrandMetrics(await getBrandMetrics());
          setUserMetrics(await getUserMetrics());
          setFeedbackMetrics(await getFeedbackMetrics());
          setFeatureSuggestionMetrics(await getFeatureSuggestionMetrics());
          setAccountDeletionMetrics(await getAccountDeletionRequests());
        } catch (error) {
          console.error('Error fetching metrics:', error);
        }
      };
      fetchData();
    }
  }, []);

  // Ensure redirection happens only if admin is not logged in
  if (isAdminLoggedInState === false) {
    return <Navigate to="/admin-login" replace />;
  }

  if (isAdminLoggedInState === null) {
    // Don't render anything until the state is decided (avoiding potential flicker)
    return null;
  }

  const renderCard = (title, total, details, buttonText, navigateTo) => (
    <div className="bg-white shadow-lg rounded-lg p-6 m-2 min-h-[180px] flex flex-col justify-between transform transition-transform hover:scale-105 card-container">
      <div className="card-front">
        <Typography variant="h6" className="text-gray-700 font-semibold">{title}</Typography>
        <Typography variant="h3" color="primary" className="text-indigo-600 font-bold text-5xl">{total}</Typography>
      </div>
      <div className="card-back flex flex-col items-start gap-1 mt-4">
        {details.map((detail, index) => (
          <Typography key={index} variant="body2" className="text-gray-600 text-sm">{detail}</Typography>
        ))}
        <Button
          variant="contained"
          color="primary"
          className="mt-4 bg-indigo-600 text-white w-full hover:bg-indigo-700"
          onClick={() => navigate(navigateTo)}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom className="text-center text-gray-800 font-semibold my-4">
        Admin Dashboard
      </Typography>

      <Grid container spacing={4}>
        {/* Brands Metrics */}
        <Grid item xs={12} sm={6} md={3}>
          {renderCard('Brands', brandMetrics.total || 0, [
            `Added by Admin: ${brandMetrics.addedByAdmin || 0}`,
            `Added by User: ${brandMetrics.addedByUsers || 0}`,
            `Recently Added: ${brandMetrics.recentlyAdded || 0}`
          ], 'View Brands', '/admin/brands')}
        </Grid>

        {/* User Metrics */}
        <Grid item xs={12} sm={6} md={3}>
          {renderCard('Users', userMetrics.total || 0, [
            `Total Users: ${userMetrics.total || 0}`
          ], 'View Users', '/admin/users')}
        </Grid>

        {/* Feedback Metrics */}
        <Grid item xs={12} sm={6} md={3}>
          {renderCard('Feedback', feedbackMetrics.total || 0, [
            `New Feedback: ${feedbackMetrics.new || 0}`,
            `Reviewed Feedback: ${feedbackMetrics.reviewed || 0}`
          ], 'View Feedback', '/admin/feedback')}
        </Grid>

        {/* Feature Suggestions Metrics */}
        <Grid item xs={12} sm={6} md={3}>
          {renderCard('Feature Suggestions', featureSuggestionMetrics.total || 0, [
            `New: ${featureSuggestionMetrics.new || 0}`,
            `Active: ${featureSuggestionMetrics.active || 0}`,
            `Saved for Later: ${featureSuggestionMetrics.savedForLater || 0}`
          ], 'View Suggestions', '/admin/feature-suggestions')}
        </Grid>

        {/* Account Deletion Requests Metrics */}
        <Grid item xs={12} sm={6} md={3}>
          {renderCard('Account Deletion Requests', accountDeletionMetrics.total || 0, [
            `Pending: ${accountDeletionMetrics.pending || 0}`,
            `Completed: ${accountDeletionMetrics.completed || 0}`
          ], 'View Requests', '/admin/account-deletion')}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
