import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../components/firebase';
import { Button, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as ViewIcon } from '@mui/icons-material';
import AddUser from '../../components/AddUser';
import EditUser from '../../components/AdminEditUser';
import ViewUser from '../../components/AdminViewUser';
import DeleteUserConfirmation from '../../components/DeleteUserConfirmation';
import dayjs from 'dayjs';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    // Fetch users from Firestore
    const fetchUsers = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'users'));
            const userData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            setUsers(userData);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const calculateRemainingDays = (endDate) => {
        if (!endDate) return null;
        const end = dayjs(endDate.toDate());
        const today = dayjs();
        return end.diff(today, 'day');
    };

    return (
        <div className="p-8">
            <div className="flex justify-between">
                <Typography
                    variant="h4"
                    className="text-xl font-semibold mb-4">
                    Users Management
                </Typography>                
                <AddUser onAdd={fetchUsers} />
            </div>
            <div className="overflow-x-auto shadow-lg rounded-lg">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-100">
                        <tr className='text-center'>
                            <th className="py-2 px-4 border text-center">Name</th>
                            <th className="py-2 px-4 border text-center">Remaining Days</th>
                            <th className="py-2 px-4 border text-center">Role</th>
                            <th className="py-2 px-4 border text-center">Link Generated</th>
                            <th className="py-2 px-4 border text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id} className="text-center hover:bg-gray-50">
                                <td className="relative py-2 px-4 border group">
                                    <span>{user.name}</span>
                                    <span className="absolute left-full top-0 bg-gray-800 text-white text-sm rounded p-1 opacity-0 group-hover:opacity-100 transition-opacity">
                                        {user.email}
                                    </span>
                                </td>                                <td className={`py-2 px-4 border ${calculateRemainingDays(user.endDate) < 0 ? 'text-red-600' : 'text-green-600'}`}>
                                    {calculateRemainingDays(user.endDate) < 0
                                        ? `Overdue by ${-calculateRemainingDays(user.endDate)} days`
                                        : `${calculateRemainingDays(user.endDate)} days`}
                                </td>
                                <td className="py-2 px-4 border">{user.role}</td>
                                <td className="py-2 px-4 border">{user.productIds ? Object.keys(user.productIds).length : 0}</td>

                                <td className="py-2 px-4 border space-x-2">
                                    <Button onClick={() => { setSelectedUser(user); setViewModalOpen(true); }} startIcon={<ViewIcon />} color="info" size="small">
                                        View
                                    </Button>
                                    <Button onClick={() => { setSelectedUser(user); setDeleteModalOpen(true); }} startIcon={<DeleteIcon />} color="error" size="small">
                                        Delete
                                    </Button>
                                    <Button onClick={() => { setSelectedUser(user); setEditModalOpen(true); }} startIcon={<EditIcon />} color="primary" size="small">
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modals */}
            {viewModalOpen && <ViewUser user={selectedUser} onClose={() => setViewModalOpen(false)} />}
            {editModalOpen && <EditUser user={selectedUser} onClose={() => { setEditModalOpen(false); fetchUsers(); }} />}
            {deleteModalOpen && (
                <DeleteUserConfirmation
                    userId={selectedUser?.id}
                    onClose={() => setDeleteModalOpen(false)}
                    onDelete={fetchUsers}
                />
            )}
        </div>
    );
};

export default Users;
