import React, { useState } from 'react';
import { db } from './firebase'; // Import your Firebase configuration
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { CircularProgress, Snackbar, Alert } from '@mui/material';

const BrandInput = ({ brands, setBrands }) => {
    const [brand, setBrand] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const addBrand = async () => {
        if (brand.trim()) {
            setLoading(true);
            setError(null);

            // Get userId from localStorage
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user?.uid;

            if (!userId) {
                setError('User not logged in. Please log in and try again.');
                setLoading(false);
                return;
            }

            try {
                // Firestore reference to the user's document in the 'brands' collection
                const userDocRef = doc(db, 'brands', userId);

                // Check if the document exists
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    // Update existing document by adding a brand to the array
                    await updateDoc(userDocRef, {
                        brandNames: arrayUnion(brand), // Add brand to array
                    });
                } else {
                    // Create a new document with the initial brand
                    await setDoc(userDocRef, {
                        brandNames: [brand], // Initialize array with first brand
                        createdAt: new Date(),
                    });
                }

                // Update local state and localStorage
                const updatedBrands = [...brands, brand];
                setBrands(updatedBrands);
                localStorage.setItem('brands', JSON.stringify(updatedBrands));

                setSuccess(true);
                setBrand(''); // Clear input
                setShowInput(false);
            } catch (error) {
                setError('Failed to add brand. Please try again.');
            } finally {
                setLoading(false);
            }
        } else {
            setError('Brand name cannot be empty.');
        }
    };

    return (
        <div className="p-2 max-w-lg mx-auto">
            {/* Toggle button */}
            <button
                onClick={() => setShowInput(!showInput)}
                className="px-6 py-2 bg-blue-500 text-white rounded-lg mb-1"
            >
                {showInput ? 'Close' : 'Add Brand'}
            </button>

            {/* Input form */}
            {showInput && (
                <div className="flex items-center gap-4">
                    <input
                        type="text"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Enter brand name"
                    />
                    <button
                        onClick={addBrand}
                        disabled={loading}
                        className="px-6 py-2 bg-green-500 text-white rounded-lg flex items-center justify-center"
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Add'}
                    </button>
                </div>
            )}

            {/* Success and Error Snackbar */}
            <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Brand added successfully!
                </Alert>
            </Snackbar>
            <Snackbar open={!!error} autoHideDuration={4000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default BrandInput;
