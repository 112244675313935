import React from 'react';
import { FaPeopleArrows, FaCog, FaRocket, FaLightbulb, FaEnvelope, FaPhone, FaShieldAlt, FaHandsHelping } from 'react-icons/fa';
import ContactUs from './ContactUs'; // Importing the ContactUs component

const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 px-4 md:px-8 py-8">
      {/* Header Section */}
      <section className="bg-blue-500 text-white py-12 mb-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-center">About Us</h1>
        <p className="mt-4 text-center text-lg">Discover who we are, our services, and our future plans</p>
      </section>

      {/* Who We Are Section */}
      <section className="py-12 px-6 md:px-20 mb-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold text-center mb-6 flex items-center justify-center gap-2">
          <FaPeopleArrows /> Who We Are
        </h2>
        <p className="text-center max-w-3xl mx-auto text-lg">
          We are a dedicated team committed to providing top-notch services that cater to our clients' unique needs.
          Our goal is to innovate and lead with integrity, creating solutions that stand out.
        </p>
      </section>

      {/* Our Services Section */}
      <section className="bg-white py-12 px-6 md:px-20 mb-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold text-center mb-6">Our Services</h2>
        <div className="flex flex-wrap justify-center gap-8">
          <ServiceCard icon={<FaCog />} title="Tech Services" description="Innovative tech solutions to boost your business." />
          <ServiceCard icon={<FaRocket />} title="Growth Analytics" description="Data-driven strategies for maximum growth." />
          <ServiceCard icon={<FaLightbulb />} title="Consulting" description="Expert advice for navigating your challenges." />
        </div>
      </section>

      {/* Unique Features Section */}
      <section className="py-12 px-6 md:px-20 mb-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold text-center mb-6">What Sets Us Apart</h2>
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          <FeatureCard icon={<FaRocket />} title="Innovation" description="We stay ahead of trends to deliver innovative solutions." />
          <FeatureCard icon={<FaShieldAlt />} title="Reliability" description="Trustworthy services that you can count on." />
          <FeatureCard icon={<FaHandsHelping />} title="Client Focus" description="Our clients are at the heart of everything we do." />
        </div>
      </section>

      {/* Future Plans Section */}
      <section className="bg-gray-200 py-12 px-6 md:px-20 mb-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold text-center mb-6 flex items-center justify-center gap-2">
          <FaLightbulb /> Something's Cooking
        </h2>
        <div className="text-center max-w-3xl mx-auto text-lg">
          <p>We are constantly working on new ideas to enhance our services. Here’s what’s coming up:</p>
          <ul className="list-disc list-inside mt-4">
            <li>Enhanced AI-driven analytics to deliver even more accurate insights</li>
            <li>New client dashboard for personalized reports</li>
            <li>Upcoming mobile app for on-the-go access to your data</li>
          </ul>
        </div>
      </section>

      {/* Get in Touch Section with Contact Us Component */}
      <section className="py-12 px-6 md:px-20 mb-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold text-center mb-6">Get in Touch</h2>
        <div className="flex flex-col md:flex-row justify-center gap-8">
          <ContactUs /> {/* Integrating the ContactUs Component */}
        </div>
      </section>
    </div>
  );
};

const ServiceCard = ({ icon, title, description }) => (
  <div className="bg-white shadow-md p-6 rounded-lg text-center max-w-xs">
    <div className="text-5xl text-blue-500 mb-4">{icon}</div>
    <h3 className="text-xl font-bold">{title}</h3>
    <p className="mt-2 text-gray-700">{description}</p>
  </div>
);

const FeatureCard = ({ icon, title, description }) => (
  <div className="p-6 bg-blue-50 rounded-lg text-center shadow-md">
    <div className="text-4xl text-blue-600 mb-4">{icon}</div>
    <h3 className="text-lg font-semibold">{title}</h3>
    <p className="mt-2">{description}</p>
  </div>
);

export default AboutUs;
