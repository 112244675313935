import React, { useState } from 'react';
import { db } from './firebase'; // Firebase configuration
import { collection, addDoc } from 'firebase/firestore';
import { Modal, Box, Button, Snackbar, Alert } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const categories = ['Joining', 'Feedback', 'Support', 'Sales', 'Other'];

const ContactUs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleSubmit = async () => {
        if (!category || !title || !message || !contactInfo) {
            setError('All fields are required.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            // Save to Firestore
            await addDoc(collection(db, 'contact-us'), {
                category,
                title,
                message,
                contactInfo,
                createdAt: new Date(),
            });
            setSuccess(true);
            setTitle('');
            setMessage('');
            setContactInfo('');
            setCategory('');
            handleCloseModal();
        } catch (error) {
            setError('Failed to submit the form. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4 text-center">
            {/* Contact Us Button */}
            <Button
                variant="contained"
                startIcon={<ContactMailIcon />}
                onClick={handleOpenModal}
                sx={{
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    padding: '10px 20px',
                    borderRadius: '25px',
                    fontSize: '1rem',
                    '&:hover': { backgroundColor: '#115293' },
                }}
            >
                Contact Us
            </Button>

            {/* Modal */}
            <Modal open={isModalOpen} onClose={handleCloseModal} aria-labelledby="contact-us-modal">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                    }}
                >
                    <h2 className="text-xl font-semibold mb-4 text-center">Contact Us</h2>

                    {/* Category Dropdown */}
                    <div className="mb-4">
                        <label className="block text-left mb-2 font-semibold">Category</label>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                        >
                            <option value="">Select a category</option>
                            {categories.map((cat) => (
                                <option key={cat} value={cat}>
                                    {cat}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Title Input */}
                    <div className="mb-4">
                        <label className="block text-left mb-2 font-semibold">Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                            placeholder="Enter title"
                        />
                    </div>

                    {/* Message Input */}
                    <div className="mb-4">
                        <label className="block text-left mb-2 font-semibold">Message</label>
                        <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                            rows="4"
                            placeholder="Enter your message"
                        />
                    </div>

                    {/* Contact Info Input */}
                    <div className="mb-4">
                        <label className="block text-left mb-2 font-semibold">Contact Info (Email/Phone)</label>
                        <input
                            type="text"
                            value={contactInfo}
                            onChange={(e) => setContactInfo(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                            placeholder="Enter your contact info"
                        />
                    </div>

                    {/* Submit Button */}
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={loading}
                        className="mt-4 bg-green-500 text-white hover:bg-green-600"
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </Box>
            </Modal>

            {/* Success Snackbar */}
            <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Your message has been submitted successfully!
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            <Snackbar open={!!error} autoHideDuration={4000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ContactUs;
