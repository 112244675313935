import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Snackbar, Alert } from '@mui/material';

const ProductCard = ({ product }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopyLink = () => {
    const productLink = `${window.location.origin}/product/${product.productId}`;
    navigator.clipboard.writeText(productLink)
      .then(() => setSnackbarOpen(true))
      .catch(() => alert('Failed to copy link.')); // Fallback in case of an error
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Card
      style={{
        width: '400px', // Consistent card width
        height: '700px', // Consistent card height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Square Image */}
      <div
        style={{
          width: '100%',
          height: '500px', // Square image container
          backgroundColor: '#fff', // White background for extra space
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={product.imageUrl || ''}
          alt={product.title || 'Product Image'}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain', // Ensures the image is fully shown without cropping
          }}
        />
      </div>

      {/* Content */}
      <CardContent style={{ flexGrow: 1, padding: '16px' }}>
        <Typography variant="h7" gutterBottom>
          {product.title || 'No Title'}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          ₹ {product.price || 'No Price Available'}
        </Typography>
        <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          {/* View Details Button */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            href={`/product/${product.productId}`}
          >
            View Product
          </Button>

          {/* Copy Link Button */}
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleCopyLink}
          >
            Copy Link
          </Button>
        </div>
      </CardContent>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Closes after 3 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default ProductCard;
