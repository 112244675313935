import React, { useState } from 'react';

const PlatformSelection = ({ setSelectedPlatform }) => {
    const [platform, setPlatform] = useState('Amazon'); // Default selection

    const handlePlatformChange = (e) => {
        const selectedPlatform = e.target.value;
        setPlatform(selectedPlatform);
        setSelectedPlatform(selectedPlatform); // Pass the selected platform to parent
    };

    return (
        <div className="p-2 bg-gray-100 rounded-lg shadow-md w-full max-w-xl mx-auto">
            <h2 className="text-xl font-semibold text-gray-700 mb-4 text-center">Choose Platform:</h2>
            <div className="grid grid-cols-2 gap-1 w-full sm:grid-cols-3 md:grid-cols-5">
                {['Amazon', 'Flipkart', 'Myntra', 'Nykaa', 'Meesho'].map((platformOption) => (
                    <label
                        key={platformOption}
                        className={`flex items-center justify-center px-4 py-2 rounded-lg cursor-pointer transition-colors ${
                            platform === platformOption
                                ? 'bg-blue-600 text-white'
                                : 'bg-white text-gray-700 border border-gray-300'
                        } hover:bg-blue-500 hover:text-white`}
                    >
                        <input
                            type="radio"
                            name="platform"
                            value={platformOption}
                            checked={platform === platformOption}
                            onChange={handlePlatformChange}
                            className="hidden"
                        />
                        {platformOption}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default PlatformSelection;
