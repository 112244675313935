import React from 'react';

const OptionsSelection = ({ options, setOptions }) => {
    const handleCheckboxChange = (e) => {
        setOptions({ ...options, [e.target.name]: e.target.checked });
    };

    return (
        <div className="p-2 bg-gray-100 rounded-lg shadow-md w-full max-w-xl mx-auto mt-1 mb-1">
            <h2 className="text-xl font-semibold text-gray-700 mb-4 text-center">Select Options:</h2>
            <div className="grid grid-cols-2 gap-1 w-full sm:grid-cols-4">
                {['Review', 'Rating', 'Submitted', 'Only Order'].map((option) => (
                    <label
                        key={option}
                        className={`flex items-center justify-center px-4 py-2 rounded-lg cursor-pointer transition-colors ${
                            options[option.toLowerCase().replace(/\s+/g, '')]
                                ? 'bg-blue-600 text-white'
                                : 'bg-white text-gray-700 border border-gray-300'
                        } hover:bg-blue-500 hover:text-white`}
                    >
                        <input
                            type="checkbox"
                            name={option.toLowerCase().replace(/\s+/g, '')}
                            checked={options[option.toLowerCase().replace(/\s+/g, '')] || false}
                            onChange={handleCheckboxChange}
                            className="hidden"
                        />
                        {option}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default OptionsSelection;
