import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../components/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../components/firebase';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import bcrypt from 'bcryptjs'; // Import bcrypt for password comparison
import { getPartnerTag } from '../helpers/getPartnerTag';
import { isUserLoggedIn } from '../utils/authUtils';


const UserLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const { login } = useAuth();

  useEffect(() => {
    isUserLoggedIn()
    if (isUserLoggedIn()) {
      navigate('/user/dashboard');
    }
  }, [navigate]);


  const handleLogin = async () => {
    setLoading(true); // Start loading

    try {
      setEmail(email.toLowerCase()); // Normalize email to lowercase for consistency

      // Firebase Authentication
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      // If login is successful, proceed
      if (userCredential.user) {

        // Store user info in localStorage if login is successful
        localStorage.setItem('user', JSON.stringify({
          uid: email,
          isLoggedIn: true,
          loginDate: new Date().toISOString(),
          partnerTag: await getPartnerTag(email), 
          userId: userCredential.user.uid,
        }));

        login('user'); 
        navigate('/user/dashboard'); // Redirect to user dashboard

      }
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError('Invalid password');
      } else if (error.code === 'auth/user-not-found') {
        setError('No user found with this email');
      } else {
        setError('An error occurred during login');
      }
      console.error('Login error:', error.message);
    }

    setLoading(false); // Stop loading
  };


  /* const handleLogin = async () => {
    setLoading(true); // Start loading
    try {
      setEmail(email.toLowerCase());

      // Firebase Authentication
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      if (userCredential.user) {

        localStorage.setItem('user', JSON.stringify({
          uid: email,
          isLoggedIn: true,
          loginDate: new Date().toISOString(),
          partnerTag: await getPartnerTag(email),
          userId: userCredential.user.uid,
        }));

      }
      // Query Firestore for user document based on email
      const userRef = doc(db, 'users', email); // Assuming the email is used as the document ID
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();

        // Compare provided password with stored hashed password
        const isPasswordValid = bcrypt.compareSync(password, userData.hashedPassword); // Compare hashed password

        if (isPasswordValid) {
          // Store user info in localStorage if login is successful
          localStorage.setItem('user', JSON.stringify({
            uid: email,
            isLoggedIn: true,
            loginDate: new Date().toISOString(),
            partnerTag: await getPartnerTag(email),
          }));

          // Update login count in Firestore
          const newLoginCount = (userData.loginCount || 0) + 1;
          await updateDoc(userRef, { loginCount: newLoginCount });

          login('user');
          navigate('/user-dashboard');
        } else {
          setError('Invalid password');
        }
      } else {
        setError('No user found with this email');
      }
    } catch (error) {
      setError('An error occurred during login');
      console.error('Login error:', error.message);
    }
    setLoading(false); // Stop loading
  }; */

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold text-center mb-4">User Login</h2>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            id="email"
            type="email"
            className="mt-2 p-3 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? 'text' : 'password'}
              className="mt-2 p-3 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute right-3 top-3"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff className="text-gray-600" /> : <Visibility className="text-gray-600" />}
            </button>
          </div>
        </div>

        {error && <p className="text-red-500 text-sm text-center mb-4">{error}</p>}

        <button
          onClick={handleLogin}
          disabled={loading} // Disable button when loading
          className={`w-full p-3 rounded-lg focus:outline-none focus:ring-2 ${loading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'} text-white`}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
        </button>
      </div>
    </div>
  );
};

export default UserLogin;
