// utils/authUtils.js

/**
 * Checks if the admin is logged in by checking the session storage.
 * @returns {boolean} True if logged in, false otherwise.
 */
export const isAdminLoggedIn = () => {
    return sessionStorage.getItem('admin') !== null;
  };
  
  /**
   * Checks if the user is logged in by checking the local storage and ensuring the login hasn't expired (3-day limit).
   * @returns {boolean} True if logged in and within 3 days, false otherwise.
   */
  export const isUserLoggedIn = () => {
    const userData = localStorage.getItem('user');
    
    if (!userData) return false;
  
    try {
      const { isLoggedIn, loginDate } = JSON.parse(userData);
      
      if (!isLoggedIn || !loginDate) return false;
  
      // Calculate the time difference in days
      const loginTime = new Date(loginDate).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - loginTime;
      const threeDaysInMs = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  
      // Check if the login is within the past 7 days
      if (timeDifference > threeDaysInMs) {
        localStorage.removeItem('user'); // Clear local storage if expired
        return false;
      }
  
      return true;
    } catch (error) {
      // In case of JSON parsing error or other issues
      localStorage.removeItem('user');
      return false;
    }
  };
  