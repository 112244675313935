import React from "react";

function ProductPage() {
    return (
        <div className="bg-gray-50 min-h-screen">
            <div className="max-w-7xl mx-auto p-6">
                {/* Product Container */}
                <div className="flex flex-col lg:flex-row items-start bg-white shadow-lg rounded-lg overflow-hidden">
                    {/* Image Section */}
                    <div className="w-full lg:w-1/2">
                        <img
                            src="https://m.media-amazon.com/images/I/41fUay1JyKL._SL500_.jpg"
                            alt="Planter"
                            className="w-full h-auto object-cover"
                        />
                    </div>

                    {/* Details Section */}
                    <div className="w-full lg:w-1/2 p-6">
                        <h1 className="text-2xl font-bold text-gray-800">The Art House</h1>
                        <p className="text-sm text-gray-600">Beige & White Metal Planters</p>
                        <div className="mt-4">
                            <span className="text-xl font-semibold text-pink-600">₹3,499</span>
                            <span className="text-sm line-through text-gray-400 ml-3">
                                ₹17,499
                            </span>
                            <span className="text-sm text-green-500 ml-3">(80% OFF)</span>
                        </div>
                        <p className="text-sm text-gray-500 mt-2">Inclusive of all taxes</p>

                        {/* Size Selection */}
                        <div className="mt-6">
                            <label htmlFor="size" className="block text-gray-600 font-medium">
                                SELECT SIZE
                            </label>
                            <button className="px-4 py-2 bg-gray-200 rounded-lg mt-2 hover:bg-gray-300 transition-all">
                                Onesize
                            </button>
                        </div>

                        {/* Buttons */}
                        <div className="mt-6 flex gap-4">
                            <button className="bg-pink-600 text-white px-4 py-2 rounded-lg hover:bg-pink-700">
                                ADD TO BAG
                            </button>
                            <button className="bg-gray-100 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200">
                                WISHLIST
                            </button>
                        </div>

                        <p className="mt-6 text-sm text-gray-500">Seller: AARA CREATIONS</p>
                    </div>
                </div>

                {/* Product Description */}
                <div className="bg-white shadow-lg rounded-lg mt-6 p-6">
                    <h2 className="text-xl font-bold text-gray-800">Product Description</h2>
                    <p className="text-gray-600 mt-4">
                        Add a touch of elegance to your home with these Beige & White Metal
                        Planters. Perfect for indoor plants, these planters are designed to
                        blend seamlessly with any home decor. Crafted from durable metal,
                        they are lightweight yet sturdy, ensuring long-lasting use.
                    </p>
                </div>

                {/* Features */}
                <div className="bg-white shadow-lg rounded-lg mt-6 p-6">
                    <h2 className="text-xl font-bold text-gray-800">Features</h2>
                    <ul className="list-disc list-inside mt-4 text-gray-600">
                        <li>Modern and elegant design</li>
                        <li>Durable metal construction</li>
                        <li>Lightweight and easy to move</li>
                        <li>Perfect for indoor plants</li>
                        <li>Ideal for living rooms, balconies, or offices</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ProductPage;
