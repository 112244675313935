import React, { useEffect, useState } from 'react';
import { db } from '../../components/firebase';
import { doc, getDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const AccountDeletion = () => {
  const [deletionRequests, setDeletionRequests] = useState([]);

  useEffect(() => {
    const fetchDeletionRequests = async () => {
      try {
        const requestsSnapshot = await getDocs(collection(db, 'user-delete-request'));
        const requestsData = await Promise.all(
          requestsSnapshot.docs.map(async (docSnapshot) => {
            const requestData = docSnapshot.data();
            const userId = requestData.userId;

            // Fetch user details from users collection
            const userDoc = await getDoc(doc(db, 'users', userId));
            const userData = userDoc.exists() ? userDoc.data() : {};

            // Convert timestamps to readable dates
            const joinedDate = userData.joinedDate ? userData.joinedDate.toDate().toLocaleDateString() : 'N/A';
            const requestDate = requestData.requestDate ? requestData.requestDate.toDate().toLocaleDateString() : 'N/A';

            return {
              id: docSnapshot.id,
              userId,
              name: userData.name || 'Unknown',
              joinedDate,
              requestDate
            };
          })
        );
        setDeletionRequests(requestsData);
      } catch (error) {
        console.error('Error fetching deletion requests:', error);
      }
    };

    fetchDeletionRequests();
  }, []);

  const handleAcceptDelete = async (requestId, userId) => {
    try {
      await deleteDoc(doc(db, 'users', userId));
      await deleteDoc(doc(db, 'accountDeletionRequests', requestId));
      setDeletionRequests((prev) => prev.filter((request) => request.id !== requestId));
      alert('User account deleted successfully.');
    } catch (error) {
      console.error('Error deleting user account:', error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Card sx={{ p: 4, mb: 3, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom>
          Account Deletion
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Manage account deletions requested by users.
        </Typography>
      </Card>

      <TableContainer component={Paper} elevation={3}>
        <Table aria-label="account deletion requests table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Joined Date</TableCell>
              <TableCell>Request Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deletionRequests.map((request) => (
              <TableRow key={request.id}>
                <TableCell>{request.name}</TableCell>
                <TableCell>{request.joinedDate}</TableCell>
                <TableCell>{request.requestDate}</TableCell>
                <TableCell>
                  <Box display="flex" gap={1}>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleAcceptDelete(request.id, request.userId)}
                    >
                      Accept Delete
                    </Button>
                    <IconButton
                      component={Link}
                      to={`/admin/user/${request.userId}`}
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AccountDeletion;
