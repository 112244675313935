import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUserLoggedIn } from '../../utils/authUtils';
import { 
  Container, 
  Typography, 
  Box, 
  Button, 
  TextField, 
  IconButton, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle 
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { db } from '../../components/firebase'; // Firebase setup
import { doc, getDoc, updateDoc, setDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

const UserBrand = () => {
  const navigate = useNavigate();
  const [isUserLoggedInState, setIsUserLoggedInState] = useState(null);
  const [brands, setBrands] = useState([]); // Brands from Firestore
  const [newBrand, setNewBrand] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Check login status and fetch brands
  useEffect(() => {
    if (!isUserLoggedIn()) {
      setIsUserLoggedInState(false);
      return;
    }
    setIsUserLoggedInState(true);

    fetchBrands(); // Fetch brands on component load
  }, []);

  const fetchBrands = async () => {
    setLoading(true);
    setError(null);

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user?.uid;

      if (!userId) {
        setError('User not logged in. Please log in and try again.');
        return;
      }

      const userDocRef = doc(db, 'brands', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setBrands(userData.brandNames || []);
      }
    } catch (err) {
      setError('Failed to fetch brands. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const addBrand = async () => {
    if (newBrand.trim()) {
      setLoading(true);
      setError(null);

      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user?.uid;

      if (!userId) {
        setError('User not logged in. Please log in and try again.');
        setLoading(false);
        return;
      }

      try {
        const userDocRef = doc(db, 'brands', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          await updateDoc(userDocRef, {
            brandNames: arrayUnion(newBrand),
          });
        } else {
          await setDoc(userDocRef, {
            brandNames: [newBrand],
            createdAt: new Date(),
          });
        }

        const updatedBrands = [...brands, newBrand];
        localStorage.setItem('brands', JSON.stringify(updatedBrands))
        setBrands(updatedBrands);
        setNewBrand('');
        setIsAddModalOpen(false);
      } catch (err) {
        setError('Failed to add brand. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      setError('Brand name cannot be empty.');
    }
  };

  const deleteBrand = async (brandToDelete) => {
    setLoading(true);
    setError(null);

    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user?.uid;

    if (!userId) {
      setError('User not logged in. Please log in and try again.');
      setLoading(false);
      return;
    }

    try {
      const userDocRef = doc(db, 'brands', userId);

      await updateDoc(userDocRef, {
        brandNames: arrayRemove(brandToDelete),
      });

      const updatedBrands = brands.filter((brand) => brand !== brandToDelete);
      setBrands(updatedBrands);
    } catch (err) {
      setError('Failed to delete brand. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (isUserLoggedInState === false) {
    navigate('/user-login', { replace: true });
    return null;
  }

  if (isUserLoggedInState === null) {
    return null; // Wait for login status to resolve
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ textAlign: 'center', mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Manage Your Brands
      </Typography>

      {error && (
        <Typography color="error" variant="body1" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {loading && (
        <>
        <CircularProgress sx={{ mb: 3 }} />
        </>
      )}

      <Button
        variant="contained"
        startIcon={<Add />}
        onClick={() => setIsAddModalOpen(true)}
        sx={{ mb: 3 }}
      >
        Add New Brand
      </Button>

      {brands.length > 0 ? (
        <List>
          {brands.map((brand, index) => (
            <ListItem key={index} divider>
              <ListItemText primary={brand} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteBrand(brand)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" color="text.secondary">
          No brands added yet. Start by adding a new brand!
        </Typography>
      )}

      {/* Add Brand Modal */}
      <Dialog
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      >
        <DialogTitle>Add a New Brand</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Brand Name"
            variant="standard"
            value={newBrand}
            onChange={(e) => setNewBrand(e.target.value)}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddModalOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={addBrand} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserBrand;
