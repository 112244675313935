import React, { useState } from "react";
import { Button, Typography, Box, List, ListItem, ListItemText, CircularProgress, Snackbar, Alert } from "@mui/material";
import { parse } from "papaparse"; // Assuming you use papaparse for parsing
import { db } from "../../components/firebase"; // Firebase configuration
import { doc, getDoc, setDoc, updateDoc, arrayUnion, collection } from 'firebase/firestore'; // Firebase v9+ Modular methods

const CsvUpload = () => {
  const [summary, setSummary] = useState(null);
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [allProductsData, setAllProductsData] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const csvData = event.target.result;
        const lines = csvData.split("\n").slice(1).join("\n"); // Remove metadata

        parse(lines, {
          complete: (result) => {
            setAllProductsData(result.data);
            processCsvData(result.data);
          },
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });
      };

      reader.readAsText(file);
    }
  };

  const processCsvData = (data) => {
    const summaryData = {};

    data.forEach((row) => {
      const trackingId = row["Tracking ID"];
      if (!summaryData[trackingId]) {
        summaryData[trackingId] = {
          earnings: 0,
          totalShipped: 0,
          totalReturns: 0,
          firstDate: row["Date Shipped"],
          lastDate: row["Date Shipped"],
        };
      }

      summaryData[trackingId].earnings += row["Ad Fees"];
      summaryData[trackingId].totalShipped += row["Items Shipped"];
      summaryData[trackingId].totalReturns += row["Returns"];
      summaryData[trackingId].firstDate = new Date(row["Date Shipped"]) < new Date(summaryData[trackingId].firstDate)
        ? row["Date Shipped"]
        : summaryData[trackingId].firstDate;
      summaryData[trackingId].lastDate = new Date(row["Date Shipped"]) > new Date(summaryData[trackingId].lastDate)
        ? row["Date Shipped"]
        : summaryData[trackingId].lastDate;
    });

    setSummary(summaryData);
  };

  const handleConfirmation = () => {
    setConfirmation(true);
  };

  const handleSaveData = async () => {
    if (confirmation) {
      setLoading(true); // Start loader
  
      try {
        for (const trackingId in summary) {
          const data = summary[trackingId];
  
          // Filter products for the current tracking ID
          const productsData = allProductsData.filter(item => item["Tracking ID"] === trackingId);
  
          // Group products by year and month
          const groupedByYearMonth = productsData.reduce((acc, item) => {
            const date = new Date(item["Date Shipped"]);
            const year = date.getFullYear(); // Extract year
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Extract month as two-digit
  
            const yearMonth = { year, month };
            const key = `${year}-${month}`; // Combine year and month as a key
  
            if (!acc[key]) {
              acc[key] = {
                year,
                month,
                products: [],
              };
            }
  
            acc[key].products.push({
              name: item.Name,
              asin: item.ASIN,
              price: item.Price,
              itemsShipped: item["Items Shipped"],
              returns: item.Returns,
              earnings: item["Ad Fees"],
              dateShipped: item["Date Shipped"],
            });
  
            return acc;
          }, {});
  
          // Save grouped data to Firestore
          for (const key in groupedByYearMonth) {
            const { year, month, products } = groupedByYearMonth[key];
  
            // Reference the year document under TrackingData/{trackingId}
            const yearDocRef = collection(db, `TrackingData/${trackingId}/${year}`);
  
            // Reference the month subcollection inside the year document
            const monthDocRef = doc(yearDocRef, month);
  
            // Check if the document for the month exists
            const monthDocSnapshot = await getDoc(monthDocRef);
  
            if (monthDocSnapshot.exists()) {
              // Update the document and append new products to the 'products' array
              await updateDoc(monthDocRef, {
                products: arrayUnion(...products),
              });
            } else {
              // Create a new document in the subcollection for the month
              await setDoc(monthDocRef, {
                year,
                month,
                trackingId,
                products,
              });
            }
          }
        }
  
        // After saving, show success message and close the loader
        setSnackbarMessage("Data saved successfully, grouped by year and month!");
        setSnackbarOpen(true);
      } catch (err) {
        console.error('Error saving data:', err);
        setError('Error saving data to Firestore.');
        setSnackbarMessage("Error saving data.");
        setSnackbarOpen(true);
      } finally {
        setLoading(false); // Stop loader
      }
    }
  };
  
  
  
  
  

  // Format earnings to show two decimal places and in Rupees
  const formatCurrency = (amount) => {
    return `₹ ${amount.toFixed(2)}`;
  };

  return (
    <div className="container mx-auto p-4">
      <Typography variant="h4" className="text-center mb-6">Upload CSV and Process Data</Typography>
      
      <input 
        type="file" 
        accept=".csv" 
        onChange={handleFileUpload} 
        className="border p-2 rounded mb-4 w-full" 
      />

      {error && <Typography color="error">{error}</Typography>}

      {summary && (
        <Box className="mt-4">
          <Typography variant="h5" className="mb-4">Summary</Typography>
          
          <List>
            {Object.keys(summary).map((trackingId) => {
              const data = summary[trackingId];
              return (
                <ListItem key={trackingId} className="border-b border-gray-300">
                  <ListItemText 
                    primary={<strong>Tracking ID: {trackingId}</strong>}
                    secondary={
                      <div>
                        <div><strong>Earnings: {formatCurrency(data.earnings)}</strong></div>
                        <div>Total Shipped: {data.totalShipped}</div>
                        <div>Total Returns: {data.totalReturns}</div>
                        <div>Date Range: {data.firstDate} - {data.lastDate}</div>
                      </div>
                    }
                  />
                </ListItem>
              );
            })}
          </List>

          <Button
            variant="contained"
            color={confirmation ? "success" : "primary"}
            className="mt-4"
            onClick={confirmation ? handleSaveData : handleConfirmation}
            disabled={loading} // Disable button while loading
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              confirmation ? "Save Data to Firestore" : "Confirm and Save"
            )}
          </Button>
        </Box>
      )}

      {/* Snackbar to show success or error message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CsvUpload;
