import React, { useState } from "react";
import { Checkbox, FormControlLabel, Paper, Typography, Button, Divider, IconButton, Snackbar, Alert } from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";


// ProductTemplate component
const ApiProductTemplate = ({ products }) => {
    const [includeRating, setIncludeRating] = useState(false); // Toggle for including ratings
    const [generateAllInOne, setGenerateAllInOne] = useState(false); // Toggle for generating all-in-one template
    const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar visibility state


    // Function to generate a random emoji
    const getRandomEmoji = () => {
        const emojis = ["🛒", "🔥", "💥", "🎉", "💎", "🌟"];
        return emojis[Math.floor(Math.random() * emojis.length)];
    };

    const handleCopy = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setOpenSnackbar(true); // Show snackbar on successful copy
        }).catch((err) => {
            alert("Failed to copy content: ", err); // Fallback alert in case of failure
        });
    };

    // Close snackbar
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    // Function to format product template
    const generateTemplate = (product) => {
        const { title, price } = product;
        let template = `*Amazon Review Deal*\n\n👉🏻 *${title}*\n\n*PP: ${price}*\n\n*Best Less in Market ${getRandomEmoji()}*\n\nDM for Link 🔗`;

        if (includeRating) {
            template = `*Amazon Review/Rating Deal*\n\n👉🏻 *${title}*\n\n*PP: ${price}*\n\n*Best Less in Market ${getRandomEmoji()}*\n\nDM for Link 🔗`;
        }
        return template;
    };

    // Function to generate the all-in-one template
    const generateAllInOneTemplate = () => {
        const allNames = products.map((product) => `👉🏻 *${product.title}*`).join("\n");

        // Handling price values that might be stored as numbers or strings
        const allPrices = products.map((product) => {
            const price = product.price;
            // Ensure the price is a string before performing operations
            const priceString = typeof price === "string" ? price : price.toString();
            return parseInt(priceString.replace("₹", "")); // Remove "₹" and convert to number
        });

        const lowestPrice = Math.min(...allPrices);
        const highestPrice = Math.max(...allPrices);

        if (includeRating) {
            return `*Amazon Review/Rating Deal*\n\n${allNames}\n\n*PP: ${lowestPrice} - ${highestPrice}*\n\n*Best Less in Market ${getRandomEmoji()}*\n\nDM for Link 🔗`;
        }
        return `*Amazon Review Deal*\n\n${allNames}\n\n*PP: ${lowestPrice} - ${highestPrice}*\n\n*Best Less in Market ${getRandomEmoji()}*\n\nDM for Link 🔗`;
    };

    return (
        <div className="p-6 space-y-6">
        <Typography variant="h5" className="font-semibold mb-4 text-center">Template Generator</Typography>
  
        {/* Rating Toggle */}
        <FormControlLabel
          control={<Checkbox checked={includeRating} onChange={() => setIncludeRating(!includeRating)} />}
          label="Include Rating"
          className="mb-4"
        />
  
        {/* All-in-One Template Toggle */}
        <FormControlLabel
          control={<Checkbox checked={generateAllInOne} onChange={() => setGenerateAllInOne(!generateAllInOne)} />}
          label="Generate All-in-One Template"
          className="mb-6"
        />
  
        {/* Template Display */}
        <div className="space-y-4">
          {!generateAllInOne ? (
            products.map((product, index) => (
              <Paper key={index} className="relative bg-gray-100 p-4 rounded-lg shadow-md flex flex-col min-h-[100px]">
                <div className="flex-grow">
                  <pre className="whitespace-pre-wrap break-words text-gray-700">
                    {generateTemplate(product)}
                  </pre>
                </div>
                <button
                  onClick={() => handleCopy(generateTemplate(product))}
                  className="absolute top-3 right-3 p-2 rounded-full focus:outline-none shadow bg-gray-400 hover:bg-transparent"
                >
                  <ContentCopyIcon fontSize="small" />
                </button>
              </Paper>
            ))
          ) : (
            <Paper className="relative bg-gray-100 p-4 rounded-lg shadow-md flex flex-col min-h-[100px]">
              <div className="flex-grow">
                <pre className="whitespace-pre-wrap break-words text-gray-700">
                  {generateAllInOneTemplate()}
                </pre>
              </div>
              <button
                onClick={() => handleCopy(generateAllInOneTemplate())}
                className="absolute top-3 right-3 p-2 rounded-full focus:outline-none shadow bg-gray-400 hover:bg-transparent"
              >
                <ContentCopyIcon fontSize="small" />
              </button>
            </Paper>
          )}
        </div>
  
        {/* Download Button */}
        <div className="flex justify-center mt-6">
          <Button
            variant="contained"
            color="primary"
            onClick={() => alert("Template Generated!")}
            className="w-full max-w-xs"
          >
            Download Template
          </Button>
        </div>
  
        {/* Snackbar for Success Message */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000} // Duration before the snackbar hides automatically
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            Template copied to clipboard!
          </Alert>
        </Snackbar>
      </div>
    );
};

export default ApiProductTemplate;
