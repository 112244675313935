import React, { useState, useEffect } from 'react';
import { db } from '../../components/firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import {
    Box,
    Card,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Import the visibility icon

const FeatureSuggestions = () => {
    const [featureRequests, setFeatureRequests] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [currentRequest, setCurrentRequest] = useState({
        id: '',
        title: '',
        featureRequest: '',
        createdBy: '',
        priority: '',
        assignedTo: '',
        status: '',
    });
    const [admins, setAdmins] = useState([]);
    const [users, setUsers] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Can be 'success' or 'error'

    useEffect(() => {
        const fetchFeatureRequests = async () => {
            try {
                const requestsSnapshot = await getDocs(collection(db, 'user-request-features'));
                const requestsData = requestsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const updatedRequests = requestsData.map((request) => {
                    const creator = users.find((user) => user.id === request.createdBy);
                    return {
                        ...request,
                        createdBy: creator ? creator.name : 'Unknown',
                    };
                });

                setFeatureRequests(updatedRequests);
            } catch (error) {
                console.error('Error fetching feature requests:', error);
                setSnackbarMessage('Error fetching feature requests');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        };

        const fetchAdmins = async () => {
            const adminsSnapshot = await getDocs(collection(db, 'admin'));
            const adminsData = adminsSnapshot.docs.map((doc) => ({
                id: doc.id,
                name: doc.data().name,
            }));
            setAdmins(adminsData);
        };

        const fetchUsers = async () => {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const usersData = usersSnapshot.docs.map((doc) => ({
                id: doc.id,
                name: doc.data().name,
            }));
            setUsers(usersData);
        };

        fetchFeatureRequests();
        fetchAdmins();
        fetchUsers();
    }, [users]);

    const handleEditRequest = (request) => {
        setCurrentRequest(request);
        setOpenEditDialog(true);
    };

    const handleSaveEdit = async () => {
        try {
            const { id, priority, status, assignedTo } = currentRequest;
            await updateDoc(doc(db, 'user-request-features', id), {
                priority,
                status,
                assignedTo,
            });

            setFeatureRequests((prevRequests) =>
                prevRequests.map((request) =>
                    request.id === id ? { ...request, priority, status, assignedTo } : request
                )
            );
            setOpenEditDialog(false);
            setSnackbarMessage('Feature request updated successfully.');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error updating feature request:', error);
            setSnackbarMessage('Error updating feature request');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'Low':
                return 'green';
            case 'Moderate':
                return 'orange';
            case 'High':
                return 'red';
            default:
                return 'black';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'New':
                return 'blue';
            case 'Reviewed':
                return 'gray';
            case 'In Progress':
                return 'yellow';
            case 'Testing':
                return 'purple';
            case 'Finished':
                return 'green';
            default:
                return 'black';
        }
    };

    const handleViewRequest = (request) => {
        const creator = users.find(user => user.id === request.createdBy);
        setCurrentRequest({
            ...request,
        });
        setOpenViewDialog(true);
    };

    return (
        <Box sx={{ p: 4 }}>
            <Card sx={{ p: 4, mb: 3, boxShadow: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Feature Suggestions
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Manage feature suggestions here.
                </Typography>
            </Card>

            <TableContainer component={Paper} elevation={3}>
                <Table aria-label="feature suggestions table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Assigned To</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {featureRequests.map((request) => (
                            <TableRow key={request.id}>
                                <TableCell
                                    onMouseEnter={(e) => e.target.setAttribute('title', request.featureRequest)}
                                >
                                    {request.title}
                                </TableCell>
                                <TableCell>{request.createdBy}</TableCell>
                                <TableCell style={{ color: getPriorityColor(request.priority) }}>
                                    {request.priority}
                                </TableCell>
                                <TableCell style={{ color: getStatusColor(request.status) }}>
                                    {request.status}
                                </TableCell>
                                <TableCell>{request.assignedTo}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleViewRequest(request)}>
                                        <VisibilityIcon /> {/* View icon */}
                                    </IconButton>
                                    <IconButton color="primary" onClick={() => handleEditRequest(request)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* View Dialog */}
            <Dialog open={openViewDialog} onClose={() => setOpenViewDialog(false)}>
                <DialogTitle>View Feature Request</DialogTitle>
                <DialogContent>
                    <Typography variant="h5" gutterBottom>
                        {currentRequest.title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {currentRequest.featureRequest}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Created By: {currentRequest.createdBy}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Priority: {currentRequest.priority}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Status: {currentRequest.status}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Assigned To: {currentRequest.assignedTo}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenViewDialog(false)} color="secondary">
                        Close
                    </Button>
                    <Button
                        onClick={() => handleEditRequest(currentRequest)}
                        color="primary"
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle>Edit Feature Request</DialogTitle>
                <DialogContent>
                    <Typography variant="h5" gutterBottom>
                        {currentRequest.title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {currentRequest.featureRequest}
                    </Typography>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Priority</InputLabel>
                        <Select
                            value={currentRequest.priority}
                            onChange={(e) => setCurrentRequest({ ...currentRequest, priority: e.target.value })}
                        >
                            <MenuItem value="Low">Low</MenuItem>
                            <MenuItem value="Moderate">Moderate</MenuItem>
                            <MenuItem value="High">High</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={currentRequest.status}
                            onChange={(e) => setCurrentRequest({ ...currentRequest, status: e.target.value })}
                        >
                            <MenuItem value="New">New</MenuItem>
                            <MenuItem value="Reviewed">Reviewed</MenuItem>
                            <MenuItem value="In Progress">In Progress</MenuItem>
                            <MenuItem value="Testing">Testing</MenuItem>
                            <MenuItem value="Finished">Finished</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Assigned To</InputLabel>
                        <Select
                            value={currentRequest.assignedTo}
                            onChange={(e) => setCurrentRequest({ ...currentRequest, assignedTo: e.target.value })}
                        >
                            {admins.map((admin) => (
                                <MenuItem key={admin.id} value={admin.id}>
                                    {admin.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveEdit}
                        color="primary"
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for success/error messages */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default FeatureSuggestions;
