import React, { useState, useEffect } from 'react';
import { db } from './firebase'; // Import Firebase configuration
import { doc, getDoc } from 'firebase/firestore';

const BrandList = () => {
    const [brands, setBrands] = useState([]); // Track the list of brands
    const [isOpen, setIsOpen] = useState(false); // Track the toggle state
    const [loading, setLoading] = useState(false); // Track loading state
    const [error, setError] = useState(null); // Track error state

    useEffect(() => {
        // Try to load brands from localStorage
        const savedBrands = localStorage.getItem('brands');
        if (savedBrands) {
            setBrands(JSON.parse(savedBrands)); // Load brands from localStorage
        }

        // Listen for changes in localStorage
        const handleStorageChange = () => {
            const updatedBrands = localStorage.getItem('brands');
            if (updatedBrands) {
                setBrands(JSON.parse(updatedBrands)); // Update brands from localStorage if changed
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const fetchBrandsFromFirebase = async () => {
        setLoading(true);
        setError(null); // Reset error state

        try {
            // Get userId from localStorage
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user?.uid;

            if (!userId) {
                setError('User not logged in. Please log in and try again.');
                setLoading(false);
                return;
            }

            // Reference to the user's document in Firestore
            const userDocRef = doc(db, 'brands', userId);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userBrands = userDocSnap.data().brandNames || [];
                setBrands(userBrands); // Update state with user's brands
                localStorage.setItem('brands', JSON.stringify(userBrands)); // Save to localStorage
            } else {
                setBrands([]); // If no document exists, clear brands
            }
        } catch (error) {
            console.error('Error fetching brands from Firestore:', error);
            setError('Failed to fetch brands from Firebase. Please try again.');
        } finally {
            setLoading(false); // Stop loading when done
        }
    };

    const toggleList = async () => {
        if (!isOpen) {
            await fetchBrandsFromFirebase(); // Sync data when opening the list
        }
        setIsOpen(!isOpen); // Toggle the list visibility
    };

    const handleSync = async () => {
        await fetchBrandsFromFirebase(); // Fetch the latest data
    };

    return (
        <div className="p-2 max-w-lg mx-auto">
            <div className="flex justify-between items-center mb-4">
                <button
                    onClick={toggleList}
                    className="bg-blue-500 text-white py-2 px-4 rounded-md transition duration-300 hover:bg-blue-600"
                >
                    {isOpen ? 'Hide Brands' : 'Show Brands'}
                </button>
                <button
                    onClick={handleSync}
                    className="bg-green-500 text-white py-2 px-4 rounded-md transition duration-300 hover:bg-green-600 flex items-center"
                    disabled={loading}
                >
                    <span className="material-icons-outlined mr-2">sync</span>
                    Sync
                </button>
            </div>

            {/* Show loading indicator when fetching data */}
            {loading && (
                <div className="flex justify-center mb-4">
                    <div className="w-8 h-8 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
                </div>
            )}

            {/* Show error message if fetch failed */}
            {error && (
                <div className="text-red-500 mb-4 text-center">
                    {error}
                </div>
            )}

            {/* Conditionally render the brand list */}
            {isOpen && (
                <div>
                    <h3 className="text-xl font-semibold mb-2">Stored Brand Names:</h3>
                    <ul>
                        {brands.length > 0 ? (
                            brands.map((brand, index) => (
                                <li key={index} className="py-2 px-4 border-b border-gray-200">{brand}</li>
                            ))
                        ) : (
                            <li className="text-gray-500 py-2">No brands available.</li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default BrandList;
