import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box sx={{ p: 2, mt: 4, bgcolor: 'primary.main', color: 'white', textAlign: 'center' }}>
      <Typography variant="body2">
        &copy; 2024 DealsKraft. All Rights Reserved.
      </Typography>
      <Typography variant="body2">
        <Link component={RouterLink} to="/privacy-policy" style={{ color: 'white' }}>
          Privacy Policy
        </Link> 
        | 
        <Link component={RouterLink} to="/terms-of-service" style={{ color: 'white' }}>
          Terms of Service
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
