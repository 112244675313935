import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox, Divider, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Icon for copy button
import PlatformSelection from './PlatformSelection';
import OptionsSelection from './OptionsSelection';
import { db } from './firebase'; // Import Firebase configuration
import { collection, getDocs, getDoc, setDoc, doc, updateDoc } from 'firebase/firestore'; // Firebase Firestore functions
import ShortenProductName from './ShortenProductName';

const TemplateGenerator = () => {
    const [productName, setProductName] = useState('');
    const [price, setPrice] = useState('');
    const [template, setTemplate] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState('Amazon');
    const [options, setOptions] = useState({
        review: true,
        rating: false,
        submitted: false,
        order: false,
    });
    const [lessAmounts, setLessAmounts] = useState({
        review: '',
        rating: '',
        submitted: '',
        order: '',
    });
    const [brands, setBrands] = useState([]); // State to store the brands

    useEffect(() => {
        // Check localStorage for brands
        fetchBrandsFromLocalStorage();
    }, []);


    const fetchBrandsFromLocalStorage = () => {
        // Check localStorage for brands
        const storedBrands = JSON.parse(localStorage.getItem('brands'));

        if (storedBrands) {
            // If brands are found in localStorage, use them
            setBrands(storedBrands);
        } else {
            // If brands are not found in localStorage, fetch from Firebase
            fetchBrandsFromFirebase();
        }
    }
    const fetchBrandsFromFirebase = async () => {
        try {
            const brandsSnapshot = await getDocs(collection(db, 'brands')); // Fetch brands from Firebase
            const brandsList = brandsSnapshot.docs.map(doc => doc.data().name); // Assuming each brand has a 'name' field
            setBrands(brandsList);

            // Store the fetched brands in localStorage for future use
            localStorage.setItem('brands', JSON.stringify(brandsList));

            // Optionally, store the brands back in Firebase if needed (you can skip this if you don't need this step)
            // await setDoc(doc(db, 'brands', 'brandsList'), { names: brandsList });
        } catch (error) {
            console.error("Error fetching brands from Firebase:", error);
        }
    };

    const handleLessAmountChange = (option, value) => {
        setLessAmounts((prev) => ({
            ...prev,
            [option]: value,
        }));
    };

    function getRandomEmoji() {
        const emojis = ["😀", "😂", "😍", "😎", "🥳", "🤔", "🙌", "🎉", "👍", "✨"];
        return emojis[Math.floor(Math.random() * emojis.length)];
    }

    // Generate Template Function with Count Increment
    const generateTemplate = async () => {
        let name = productName;
        fetchBrandsFromLocalStorage();
        // Remove brand names from the product name
        brands.forEach(brand => {
            const regex = new RegExp("\\b" + brand + "\\b", "gi"); // Match whole words, case insensitive
            name = name.replace(regex, "").trim(); // Remove brand names and trim spaces
        });

        // Validate inputs
        if (!name) {
            alert('Please enter the product name.');
            return;
        }
        if (!price) {
            alert('Please enter the product price (PP).');
            return;
        }

        const selectedOptions = Object.entries(options)
            .filter(([, isChecked]) => isChecked)
            .map(([option]) => option);
        if (selectedOptions.length === 0) {
            alert('Please select at least one option.');
            return;
        }

        // Build the template
        let res = '';
        let dealType = `${selectedPlatform} `;
        if (options.review && options.rating) {
            dealType += 'Review/Rating Deal';
        } else if (options.review) {
            dealType += 'Review Deal';
        } else if (options.rating) {
            dealType += 'Rating Deal';
        } else if (options.submitted) {
            dealType += 'Review Submitted Deal';
        } else if (options.order) {
            dealType += 'Only Order Deal';
        }

        res += `*${dealType}*\n\n👉🏻 *${name}*\n\n*PP: ${price}*\n\n`;
        selectedOptions.forEach(option => {
            const lessAmount = lessAmounts[option] ? ` ${lessAmounts[option]} Less\` ${getRandomEmoji()}` : '';
            res += `*\`${option.charAt(0).toUpperCase() + option.slice(1)}:${lessAmount}*\n`;
        });
        res += '\nDM for Link 🔗';

        setTemplate(res);

        // Increase the generatedTemplateCount in Firestore
        /* try {
            const userData = localStorage.getItem('user');
            const { uid } = JSON.parse(userData);
            const userId = uid; // Replace with the actual user ID, e.g., from auth context
            const userDocRef = doc(db, 'users', userId);

            // Fetch the current document
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const currentCount = userDoc.data().generatedTemplateCount || 0;
                await updateDoc(userDocRef, {
                    generatedTemplateCount: currentCount + 1,
                });
            }
        } catch (error) {
            console.error("Error updating generatedTemplateCount:", error);
        } */
    };

    const copyTemplate = () => {
        const message = document.createElement("div");
        if (template) {
            navigator.clipboard.writeText(template);
            message.textContent = "Template copied to clipboard!";
        } else {
            message.textContent = 'No template generated yet to copy!';
        }

        message.style.position = "fixed";
        message.style.top = "20px"; // Position it at the top of the page
        message.style.right = "20px"; // Position it to the right
        message.style.backgroundColor = "#4caf50"; // Green background
        message.style.color = "#fff"; // White text
        message.style.padding = "10px";
        message.style.borderRadius = "5px";
        message.style.zIndex = "1000"; // Ensure it's above other content
        document.body.appendChild(message);

        // Remove the message after 2-3 seconds
        setTimeout(() => {
            document.body.removeChild(message);
        }, 3000); // 3000 milliseconds = 3 seconds
    };

    const clearForm = () => {
        setProductName('');
        setPrice('');
        setSelectedPlatform('Amazon');
        handleLessAmountChange('review', '');
        handleLessAmountChange('rating', '');
        handleLessAmountChange('submitted', '');
        handleLessAmountChange('order', '');

        // Clear the generated template
        setTemplate('');
        setOptions({
            review: true,
            rating: false,
            submitted: false,
            order: false,
        });
    }

    return (
        <div className="max-w-lg mx-auto p-2">
            <PlatformSelection selectedPlatform={selectedPlatform} setSelectedPlatform={setSelectedPlatform} />
            <OptionsSelection options={options} setOptions={setOptions} />

            {/* Product Name Field */}
            <div className="mb-1">
                <label className="block text-gray-700 font-semibold m-px">Product Name</label>
                <input
                    type="text"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                    placeholder="Enter product name"
                />
            </div>

            {/* Price Field */}
            <div className="mb-1">
                <label className="block text-gray-700 font-semibold m-px">Price (PP)</label>
                <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                    placeholder="Enter price"
                />
            </div>

            {/* Conditional Less Amount Fields */}
            {options.review && (
                <div className="mb-1">
                    <label className="block text-gray-700 font-semibold m-px">Review Less</label>
                    <input
                        type="number"
                        value={lessAmounts.review}
                        onChange={(e) => handleLessAmountChange('review', e.target.value)}
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        placeholder="Enter review less amount"
                    />
                </div>
            )}
            {options.rating && (
                <div className="mb-1">
                    <label className="block text-gray-700 font-semibold mb-1">Rating Less</label>
                    <input
                        type="number"
                        value={lessAmounts.rating}
                        onChange={(e) => handleLessAmountChange('rating', e.target.value)}
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        placeholder="Enter rating less amount"
                    />
                </div>
            )}
            {options.submitted && (
                <div className="mb-1">
                    <label className="block text-gray-700 font-semibold mb-1">Review Submitted Less</label>
                    <input
                        type="number"
                        value={lessAmounts.submitted}
                        onChange={(e) => handleLessAmountChange('submitted', e.target.value)}
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        placeholder="Enter review submitted less amount"
                    />
                </div>
            )}
            {options.order && (
                <div className="mb-1">
                    <label className="block text-gray-700 font-semibold mb-1">Only Order Less</label>
                    <input
                        type="number"
                        value={lessAmounts.order}
                        onChange={(e) => handleLessAmountChange('order', e.target.value)}
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        placeholder="Enter only order less amount"
                    />
                </div>
            )}

            {/* Buttons */}
            <div className="flex justify-center gap-4 mb-1">
                <button
                    onClick={generateTemplate}
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Generate Template
                </button>
                <button
                    onClick={clearForm}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-lg focus:outline-none focus:ring focus:ring-gray-200"
                >
                    Clear Form
                </button>
            </div>

            {/* Generated Template Display */}
            <div className="relative bg-gray-100 p-4 rounded-lg shadow-md flex flex-col min-h-[100px]">
                <pre className="whitespace-pre-wrap break-words text-gray-700 flex-grow">{template}</pre>
                <button
                    onClick={copyTemplate}
                    className="absolute top-3 right-3 p-2 rounded-full focus:outline-none shadow  bg-gray-400 hover:bg-transparent"
                >
                    <ContentCopyIcon fontSize="small" />
                </button>
            </div>

        </div>
    );
};

export default TemplateGenerator;
