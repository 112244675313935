import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import { AdminPanelSettings, Login, Description, Dashboard, PersonAdd } from '@mui/icons-material';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  useEffect(() => {
    // Check session or local storage for login status and set states accordingly
    const userSession = localStorage.getItem('user');
    const adminSession = sessionStorage.getItem('admin');
    setIsUserLoggedIn(!!userSession);
    setIsAdminLoggedIn(!!adminSession);
  }, []);

  const handleGoToAdminLogin = () => navigate('/admin-login');
  const handleGoToUserLogin = () => navigate('/user-login');
  const handleGoToSignUp = () => navigate('/signup');
  const handleGoToTemplateGenerator = () => navigate('/templategenerator');

  const handleGoToDashboard = () => {
    if (isAdminLoggedIn) {
      navigate('/admin-dashboard');
    } else if (isUserLoggedIn) {
      navigate('/user-dashboard');
    }
  };

  return (
    <Container
      component="main"
      sx={{
        maxWidth: {
          xs: '100%', // full width for mobile
          sm: 600,
          md: 960,
          lg: 1280,
        },
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          marginTop: 8,
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#1a73e8' }}>
          Welcome to Deal Craft!
        </Typography>
        <Typography variant="body1" paragraph sx={{ marginBottom: 3 }}>
          Your go-to platform for crafting personalized deals and templates.
        </Typography>

        {/* Conditionally Render Buttons */}
        {isUserLoggedIn || isAdminLoggedIn ? (
          <Button
            variant="contained"
            fullWidth
            onClick={handleGoToDashboard}
            sx={{
              marginBottom: 2,
              backgroundColor: '#007bff',
              color: '#fff',
              '&:hover': { backgroundColor: '#0056b3' },
              padding: 1.5,
              textTransform: 'none',
              fontWeight: 'bold',
            }}
            startIcon={<Dashboard />}
          >
            Go to Dashboard
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              fullWidth
              onClick={handleGoToAdminLogin}
              sx={{
                marginBottom: 2,
                backgroundColor: '#dc3545',
                '&:hover': { backgroundColor: '#c82333' },
                padding: 1.5,
                textTransform: 'none',
                fontWeight: 'bold',
              }}
              startIcon={<AdminPanelSettings />}
            >
              Admin Login
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleGoToUserLogin}
              sx={{
                marginBottom: 2,
                borderColor: '#28a745',
                color: '#28a745',
                '&:hover': { borderColor: '#218838', color: '#218838' },
                padding: 1.5,
                textTransform: 'none',
                fontWeight: 'bold',
              }}
              startIcon={<Login />}
            >
              User Login
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleGoToSignUp}
              sx={{
                marginBottom: 2,
                backgroundColor: '#6c757d',
                color: '#fff',
                '&:hover': { backgroundColor: '#5a6268' },
                padding: 1.5,
                textTransform: 'none',
                fontWeight: 'bold',
              }}
              startIcon={<PersonAdd />}
            >
              Sign Up
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default LandingPage;
