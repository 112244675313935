// FeatureRequestForm.js
import React, { useState } from 'react';
import { Snackbar, Alert, CircularProgress } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../components/firebase';

const FeatureRequestForm = ({ userId }) => {
  const [title, setTitle] = useState('');
  const [featureRequest, setFeatureRequest] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [featureRequestError, setFeatureRequestError] = useState(false);

  const handleFeatureRequestSubmit = async () => {
    if (!title || !featureRequest) {
      setTitleError(!title);
      setFeatureRequestError(!featureRequest);
      return;
    }

    setLoading(true); // Show loading spinner
    try {
      await addDoc(collection(db, 'user-request-features'), {
        title,
        featureRequest,
        date: new Date(),
        createdBy: userId,
        status: 'New',
        priority: 'Low',
        action: 'pending',
        assignedTo: 'NA',
      });
      setTitle('');
      setFeatureRequest('');
      setShowModal(false);
      setSnackbarOpen(true); // Show success snackbar
    } catch (error) {
      console.error('Error submitting feature request:', error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setTitle('');
    setFeatureRequest('');
    setTitleError(false);
    setFeatureRequestError(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="mt-4">
      <button
        className="px-4 py-2 bg-blue-600 text-white rounded-md"
        onClick={() => setShowModal(true)}
      >
        Request Feature
      </button>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
            <h2 className="text-xl font-semibold mb-4">Feature Request</h2>

            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError(!e.target.value);
              }}
              className={`w-full p-2 border rounded-md mb-3 ${
                titleError ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {titleError && (
              <p className="text-red-500 text-sm mb-2">Title is required.</p>
            )}

            <textarea
              placeholder="Describe the feature"
              value={featureRequest}
              onChange={(e) => {
                setFeatureRequest(e.target.value);
                setFeatureRequestError(!e.target.value);
              }}
              className={`w-full p-2 border rounded-md mb-3 ${
                featureRequestError ? 'border-red-500' : 'border-gray-300'
              }`}
              rows="4"
            />
            {featureRequestError && (
              <p className="text-red-500 text-sm mb-2">Feature request description is required.</p>
            )}

            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 border border-gray-400 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleFeatureRequestSubmit}
                disabled={loading}
                className="px-4 py-2 bg-blue-600 text-white rounded-md flex items-center"
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" className="mr-2" />
                ) : (
                  'Submit Feature Request'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Feature request submitted! Thank you 🙌
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FeatureRequestForm;
