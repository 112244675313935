import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../../components/firebase'; // Import Firebase configuration
import { CircularProgress, Box } from '@mui/material';
import ProductCardList from './ProductCardList';

const ProductListing = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const itemsPerPage = 20; // Number of items per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllProductIDs = async () => {
      try {
        const productsCollectionRef = collection(db, "productIds");
        const querySnapshot = await getDocs(productsCollectionRef);

        const productData = [];
        querySnapshot.forEach((docSnap) => {
          const { id, title, imageUrl, asinId, savedTimestamp, price, percentOff, originalPrice } = docSnap.data();
          productData.push({
            id: docSnap.id,
            title,
            imageUrl,
            asinId,
            savedTimestamp,
            price,
            percentOff,
            originalPrice,
          });
        });

        const sortedProducts = productData.sort(
          (a, b) => new Date(b.savedTimestamp) - new Date(a.savedTimestamp)
        );

        setProducts(sortedProducts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching all product IDs and data:", error);
        setProducts([]);
        setLoading(false);
      }
    };

    fetchAllProductIDs();
  }, []);

  const handleViewProduct = (index) => {
    navigate(`/product/${index}`);
  };

  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentProducts = products.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-6 text-center">Product Listing</h1>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <div className="container mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {currentProducts.map((product, index) => (
              <ProductCardList
                key={product.id || index}
                product={product}
                handleViewProduct={handleViewProduct}
              />
            ))}
          </div>
          {/* Pagination Controls */}
          <div className="flex justify-center items-center mt-4">
            <button
              className={`px-4 py-2 mx-2 ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'} rounded-lg`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              className={`px-4 py-2 mx-2 ${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white hover:bg-blue-600'} rounded-lg`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductListing;
