import React from 'react';
import { FaTags, FaCartPlus, FaHeart, FaFire, FaThLarge, FaBolt, FaGift, FaListAlt, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const Home = () => {
    return (
        <div className="min-h-screen bg-gray-100 text-gray-900 px-4 md:px-8 py-8">
            {/* Hero Section */}
            <section className="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-12 mb-8 rounded-lg shadow-lg text-center">
                <h1 className="text-4xl font-bold">Welcome to DealsKraft</h1>
                <p className="mt-4 text-lg">
                    Your one-stop destination for exclusive deals, discounts, and more!
                </p>
                <div className="mt-6 flex justify-center gap-4">
                    <Link to="/allProducts">
                        <button className="bg-white text-blue-700 px-6 py-2 rounded-lg font-semibold shadow hover:bg-gray-200">
                            Explore All Products
                        </button>
                    </Link>
                    <Link to="/allProducts">
                        <button className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold shadow hover:bg-blue-500">
                            Browse Categories
                        </button>
                    </Link>
                </div>
            </section>

            {/* About DealsKraft Section */}
            <section className="py-12 px-6 md:px-20 mb-8 bg-white rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold text-center mb-6 flex items-center justify-center gap-2">
                    <FaUsers /> About DealsKraft
                </h2>
                <p className="text-center max-w-3xl mx-auto text-lg">
                    At DealsKraft, we are committed to providing our users with amazing deals on a wide range of products.
                    Our mission is to help shoppers save big while enjoying the convenience of curated offers.
                </p>
                <p className="text-center max-w-3xl mx-auto text-lg mt-4">
                    Want to be a part of our journey? Join our team and contribute to delivering the best deals to people around the globe!
                </p>
            </section>

            {/* Featured Deals Section */}
            <section className="py-12 px-6 md:px-20 mb-8 bg-white rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold text-center mb-6 flex items-center justify-center gap-2">
                    <FaFire /> Featured Deals
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                    <DealCard
                        icon={<FaBolt />}
                        title="Flash Sale"
                        description="Get up to 70% off on electronics for a limited time!"
                    />
                    <DealCard
                        icon={<FaGift />}
                        title="Holiday Specials"
                        description="Exclusive holiday discounts on top brands."
                    />
                    <DealCard
                        icon={<FaHeart />}
                        title="Trending Deals"
                        description="The most popular deals our customers love!"
                    />
                </div>
            </section>

            {/* Categories Section */}
            <section className="bg-gray-200 py-12 px-6 md:px-20 mb-8 rounded-lg shadow-lg">
                <h2 className="text-3xl font-semibold text-center mb-6 flex items-center justify-center gap-2">
                    <FaThLarge /> Browse by Categories
                </h2>
                <div className="flex flex-wrap justify-center gap-8">
                    <CategoryCard
                        icon={<FaTags />}
                        title="Electronics"
                        description="Discover the latest gadgets and tech."
                    />
                    <CategoryCard
                        icon={<FaCartPlus />}
                        title="Home & Kitchen"
                        description="Shop essentials for your home and kitchen."
                    />
                    <CategoryCard
                        icon={<FaListAlt />}
                        title="Fashion"
                        description="Trendy clothing, footwear, and accessories."
                    />
                </div>
            </section>

            {/* Call-to-Action Section */}
            <section className="bg-blue-500 text-white py-12 px-6 md:px-20 mb-8 rounded-lg shadow-lg text-center">
                <h2 className="text-3xl font-semibold mb-6">Why Choose DealsKraft?</h2>
                <p className="text-lg mb-8">
                    Enjoy handpicked deals, exclusive discounts, and a seamless shopping experience with us.
                </p>
                <Link to="/allProducts">
                    <button className="bg-white text-blue-700 px-8 py-3 rounded-lg font-bold shadow hover:bg-gray-200">
                        Start Saving Now
                    </button>
                </Link>
            </section>
        </div>
    );
};

const DealCard = ({ icon, title, description }) => (
    <div className="bg-white shadow-md p-6 rounded-lg text-center">
        <div className="text-5xl text-blue-500 mb-4">{icon}</div>
        <h3 className="text-xl font-bold">{title}</h3>
        <p className="mt-2 text-gray-700">{description}</p>
        <Link to="/allProducts">
            <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold shadow hover:bg-blue-600">
                Shop Now
            </button>
        </Link>
    </div>
);

const CategoryCard = ({ icon, title, description }) => (
    <div className="bg-blue-50 shadow-md p-6 rounded-lg text-center max-w-xs">
        <div className="text-4xl text-blue-600 mb-4">{icon}</div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="mt-2 text-gray-700">{description}</p>
        <Link to="/allProducts">
            <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold shadow hover:bg-blue-600">
                View More
            </button>
        </Link>
    </div>
);

export default Home;
