import React, { useState, useEffect } from "react";
import { Paper, Button, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ProductPost = ({ product, includeRating = false }) => {
    const [postType, setPostType] = useState("Review");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const randomEmoji = () => {
        const emojis = ["✨", "🔥", "🌟", "💥", "🚀", "🎉"];
        return emojis[Math.floor(Math.random() * emojis.length)];
    };

    const handleCopy = (content) => {
        navigator.clipboard
            .writeText(content)
            .then(() => {
                setOpenSnackbar(true); // Show snackbar on successful copy
            })
            .catch((err) => {
                alert("Failed to copy content: ", err); // Fallback alert in case of failure
            });
    };

    const generateTemplate = (product) => {
        const { ItemInfo, Offers } = product;
        const title = ItemInfo?.Title?.DisplayValue || "Name Not Found";
        const price = Offers?.Listings?.[0]?.Price?.Amount || 0;

        let template = `*Amazon ${postType} Deals* ${randomEmoji()}\n\n*${title}*\n\n*Product Price:* ${price} ${randomEmoji()}\n\n*Best Less in Market* ${randomEmoji()}\n\n*DM for Link* 🔗`;

        if (postType === "Both") {
            template = `*Amazon Review/Rating Deals* ${randomEmoji()}\n\n*${title}*\n\n*Product Price:* ${price} ${randomEmoji()}\n\n*Best Less in Market* ${randomEmoji()}\n\n*DM for Link* 🔗`;
        }
        return template;
    };

    // Simulating data load with a useEffect
    useEffect(() => {
        const simulateDataLoad = setTimeout(() => {
            setIsLoading(false);
        }, 1000); // Simulates a 1-second data load

        return () => clearTimeout(simulateDataLoad);
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Show loading state
    }

    // const content = generateTemplate(product);

    return (
        <Paper elevation={3} className="p-4 bg-white rounded-lg w-full max-w-md mb-6">
            {/* Toggle Post Type */}
            <div className="flex items-center justify-center mb-4 space-x-4">
                {["Review", "Rating", "Both"].map((type) => (
                    <Button
                        key={type}
                        onClick={() => setPostType(type)}
                        variant={postType === type ? "contained" : "outlined"}
                        color={postType === type ? "primary" : "default"}
                    >
                        {type}
                    </Button>
                ))}
            </div>

            {/* Post Content */}
            <Paper className="relative bg-gray-100 p-4 rounded-lg shadow-md flex flex-col min-h-[100px]">
              <div className="flex-grow">
                <pre className="whitespace-pre-wrap break-words text-gray-700">
                  {generateTemplate(product)}
                </pre>
              </div>
              <button
                onClick={() => handleCopy(generateTemplate(product))}
                className="absolute top-3 right-3 p-2 rounded-full focus:outline-none shadow bg-gray-400 hover:bg-transparent"
              >
                <ContentCopyIcon fontSize="small" />
              </button>
            </Paper>

            {/* Snackbar */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message="Content copied to clipboard!"
            />
        </Paper>
    );
};

export default ProductPost;
