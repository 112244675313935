import React from 'react';
import { Container, Typography, Divider, Box, Link } from '@mui/material';

function TermsAndConditions() {
  return (
    <Container maxWidth="md" sx={{ bgcolor: '#f9fafb', p: 6, borderRadius: 3, boxShadow: 3, mt: 2 }}>
      <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: '#333', mb: 3 }}>
        Terms and Conditions for Deal Craft
      </Typography>
      <Typography variant="subtitle1" sx={{ color: '#555', mb: 4, textAlign: 'center' }}>
        Effective Date: <strong>[23 November 2024]</strong>
      </Typography>
      <Divider sx={{ mb: 4 }} />

      {/** Section 1 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          By accessing or using Deal Craft, you agree to comply with these Terms and Conditions, along with any
          guidelines or rules provided by us. If you disagree with any part of these terms, you must discontinue using
          the service.
        </Typography>
      </Box>

      {/** Section 2 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1, ml: 1, mr: 1 }}>
          2. Description of Services
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          Deal Craft offers tools for creating professional shopping, marketing, and product-related posts using
          predesigned templates. Users can share these posts on various platforms, including blogs, WhatsApp, Telegram,
          and Messenger.
        </Typography>
      </Box>

      {/** Section 3 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          3. User Account and Responsibilities
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          <strong>Registration Requirements:</strong> Users must create an account to access Deal Craft's features. You
          agree to provide accurate, current, and complete registration information.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your login
          credentials and for all activities conducted under your account. Notify us immediately of any unauthorized use
          of your account.
        </Typography>
      </Box>

      {/** Section 4 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          4. Permitted Use
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          <strong>Personal and Limited Commercial Use:</strong> Deal Craft is provided for personal use or limited
          commercial use if explicitly agreed upon in a separate contract.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Prohibited Activities:</strong> You may not use Deal Craft to create, share, or distribute unlawful,
          offensive, or infringing content, or to circumvent security measures or engage in unauthorized access to the
          platform.
        </Typography>
      </Box>

      {/** Section 5 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          5. Intellectual Property
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          <strong>Ownership of Content:</strong> All templates, graphics, tools, and proprietary software provided by
          Deal Craft are the exclusive property of Deal Craft.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>User License:</strong> Deal Craft grants you a limited, non-exclusive, revocable license to use its
          tools and templates for creating posts in accordance with these Terms.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Restrictions on Use:</strong> You may not copy, modify, distribute, or publicly display any Deal Craft
          content without prior written approval.
        </Typography>
      </Box>

      {/** Section 6 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          6. User-Generated Content
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          <strong>User Responsibility:</strong> You are solely responsible for the content you create and share using
          Deal Craft.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>License Grant:</strong> By using Deal Craft, you grant us a royalty-free, non-exclusive, worldwide
          license to use, display, and distribute your content solely in connection with providing our services.
        </Typography>
      </Box>

      {/** Section 7 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          7. Privacy and Data Security
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          <strong>Data Use:</strong> Deal Craft values your privacy. We collect and use your data as outlined in our{' '}
          <Link href="/privacy-policy" sx={{ color: 'primary.main', textDecoration: 'none' }}>
            Privacy Policy
          </Link>
          , which details cookies, data storage, and sharing practices.
        </Typography>
      </Box>

      {/** Final Sections */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          8. Disclaimer of Warranties
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          Deal Craft is provided “as-is” and “as-available,” with no guarantees of availability, accuracy, or security.
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          9. Limitation of Liability
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          Deal Craft is not liable for indirect, incidental, or consequential damages. Our maximum liability is limited
          to fees paid in the past six months.
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          10. Contact Information
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          For inquiries, contact us at{' '}
          <Link href="mailto:support@dealcraft.com" sx={{ color: 'primary.main', textDecoration: 'none' }}>
            support@dealcraft.com
          </Link>
          .
        </Typography>
      </Box>

      <Divider sx={{ my: 4 }} />
      <Typography variant="body2" sx={{ textAlign: 'center', color: '#888' }}>
        Effective Date: <strong>[23 November 2024]</strong>
      </Typography>
    </Container>
  );
}

export default TermsAndConditions;
