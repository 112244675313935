import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdminLoggedIn, isUserLoggedIn } from '../utils/authUtils';

import BrandInput from '../components/BrandInput';
import TemplateGenerator from '../components/TemplateGenerator';
import BrandList from '../components/BrandList';
import { db } from '../components/firebase';
import { collection, getDocs } from 'firebase/firestore';
import TermsAndConditionsPopup from '../components/TermsAndConditionsPopup.js';

const TemplateGeneratorPage = () => {
    const [brands, setBrands] = useState([]);
    const [agreeTnC, setAgreeTnC] = useState(false); // Initial value from Firestore


    const navigate = useNavigate();

    // Check if the user or admin is logged in, else redirect
    useEffect(() => {
        document.title = 'Template Generator';
        if (!isAdminLoggedIn() && !isUserLoggedIn()) {
            navigate('/user-login'); // Redirect to login page if not logged in
        }
    }, [navigate]);

    // Fetch brands from Firestore
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const brandCollection = collection(db, 'brands');
                const brandSnapshot = await getDocs(brandCollection);
                const brandData = brandSnapshot.docs.map(doc => doc.data().name);
                setBrands(brandData);
            } catch (error) {
                console.error('Error fetching brands from Firestore:', error);
            }
        };

        fetchBrands();
    }, []);

    return (
        <div>
            <TermsAndConditionsPopup  />

            <h1 className="text-2xl font-bold mb-1">Template Generator</h1>
            <TemplateGenerator />
            <BrandInput brands={brands} setBrands={setBrands} />
            <BrandList brands={brands} />
        </div>
    );
};

export default TemplateGeneratorPage;
