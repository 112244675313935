// /pages/admin/Brands.js
import React, { useState, useEffect } from 'react';
import { db } from '../../components/firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentBrand, setCurrentBrand] = useState({ id: '', name: '' });

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandsSnapshot = await getDocs(collection(db, 'brands'));
        const brandsData = brandsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setBrands(brandsData);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();
  }, []);

  const handleDeleteBrand = async (brandId) => {
    try {
      await deleteDoc(doc(db, 'brands', brandId));
      setBrands((prevBrands) => prevBrands.filter((brand) => brand.id !== brandId));
      alert('Brand deleted successfully.');
    } catch (error) {
      console.error('Error deleting brand:', error);
    }
  };

  const handleEditBrand = (brand) => {
    setCurrentBrand(brand);
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      await updateDoc(doc(db, 'brands', currentBrand.id), {
        name: currentBrand.name,
      });
      setBrands((prevBrands) =>
        prevBrands.map((brand) =>
          brand.id === currentBrand.id ? { ...brand, name: currentBrand.name } : brand
        )
      );
      setOpenEditDialog(false);
      alert('Brand updated successfully.');
    } catch (error) {
      console.error('Error updating brand:', error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Card sx={{ p: 4, mb: 3, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom>
          Brands
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Manage brands here.
        </Typography>
      </Card>

      <TableContainer component={Paper} elevation={3}>
        <Table aria-label="brands table">
          <TableHead>
            <TableRow>
              <TableCell>Brand Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands.map((brand) => (
              <TableRow key={brand.id}>
                <TableCell>{brand.name}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditBrand(brand)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteBrand(brand.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Brand</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Brand Name"
            fullWidth
            value={currentBrand.name}
            onChange={(e) => setCurrentBrand({ ...currentBrand, name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Brands;
