import { getFirestore, doc, getDoc } from "firebase/firestore";
import { db } from "../components/firebase";

// Function to fetch PartnerTag from Firebase
export const getPartnerTag = async (userEmail) => {
  try {
    // Initialize Firestore
    // const db = getFirestore();

    // Reference the document path
    const docRef = doc(db, "users", userEmail);

    // Fetch the document
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Extract PartnerTag from the document
      const partnerTag = docSnap.data().PartnerTag;

      if (partnerTag) {
        return partnerTag; // Return the PartnerTag if it exists
      } else {
        throw new Error("PartnerTag not found for this user.");
      }
    } else {
      throw new Error("User document does not exist.");
    }
  } catch (error) {
    console.error("Error fetching PartnerTag:", error.message);
    throw error; // Re-throw error for handling in the calling code
  }
};
