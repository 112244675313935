import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, Timestamp } from 'firebase/firestore';
import { isUserLoggedIn } from '../../utils/authUtils';
import { db } from '../../components/firebase';
import { useNavigate, Navigate } from 'react-router-dom';
import UserToolTab from '../../components/HelperUser/UserToolTab';
import UserSupportTab from '../../components/HelperUser/UserSupportTab';

const UserDashboardNew = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [activeTab, setActiveTab] = useState('Stats');
  const [userName, setUserName] = useState(null);
  const [isUserLoggedInState, setIsUserLoggedInState] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userLocalStorage, setUserLocalStorage] = useState(null);
  const [localBrands, setLocalBrands] = useState(null);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      setIsUserLoggedInState(false);
      return;
    }
    document.title = "User Dashboard";

    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUserId(storedUser?.uid || null);
    setUserLocalStorage(storedUser);
    setIsUserLoggedInState(true);

    const brands = JSON.parse(localStorage.getItem('brands'));
    setLocalBrands(brands || []);

    if (storedUser?.uid) {
      const fetchUser = async () => {
        try {
          const userRef = doc(db, 'users', storedUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const data = userSnap.data();
            setUserData(data);
            setUserName(data.name || '');
          } else {
            setUserName(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUser();
    }
  }, []);

  const formatTimestamp = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleDateString();
    }
    return timestamp;
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  if (isUserLoggedInState === false) {
    return <Navigate to="/user-login" replace />;
  }

  if (isUserLoggedInState === null) {
    return null;
  }

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4 md:p-6 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
        {/* User Details */}
        <div className="flex-shrink-0 w-full md:w-1/3 bg-gray-50 p-4 rounded-lg">
          <div className="flex flex-col items-center text-center">
            <div className="bg-blue-100 text-blue-700 rounded-full w-20 h-20 flex items-center justify-center text-4xl mb-4">
              😊
            </div>
            <h3 className="text-lg font-bold text-gray-800">{userData.name}</h3>
            <p className="text-gray-600 text-sm">{userData.email}</p>
            <div className="mt-4 text-sm space-y-2">
              <p><span className="font-medium text-gray-800">Partner Tag:</span> {userData.partnerTag}</p>
              <p><span className="font-medium text-gray-800">Start Date:</span> {formatTimestamp(userData.startDate)}</p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="w-full md:w-2/3">
          <h2 className="text-xl font-bold text-gray-800 mb-4">Dashboard</h2>

          {/* Tab buttons */}
          <div className="flex border-b mb-4 space-x-2 md:space-x-4 overflow-x-auto">
            {['Stats', 'Tools', 'UserSupport', 'Membership', 'Affiliate'].map((tab) => (
              <div
                key={tab}
                className={`px-4 py-2 text-gray-600 border-b-2 border-transparent cursor-pointer whitespace-nowrap ${activeTab === tab
                    ? 'border-blue-500 font-bold text-blue-600'
                    : 'hover:border-blue-500 hover:text-blue-500'
                  }`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </div>
            ))}
          </div>

          {/* Tab content */}
          <div className="h-auto overflow-y-auto">
            {activeTab === 'Stats' && (
              <div className="grid gap-4">
                <div className="bg-blue-100 p-4 rounded flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-semibold">Affiliate Links</h3>
                    <p className="text-gray-600">Links created</p>
                  </div>
                  <span className="text-2xl font-bold text-blue-700">{Object.keys(userData.productIds).length}</span>
                </div>
                <div className="bg-green-100 p-4 rounded flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-semibold">Brands Added</h3>
                    <p className="text-gray-600">Brands added</p>
                  </div>
                  <span className="text-2xl font-bold text-green-700">{localBrands.length || 0}</span>
                </div>
              </div>
            )}

            {activeTab === 'Tools' && <UserToolTab />}
            {activeTab === 'UserSupport' && <UserSupportTab userId={userId} />}
            {activeTab === 'Membership' && (
              <div>
                <h3 className="text-lg font-semibold">Membership Details</h3>
                <p>Will be available soon...</p>
              </div>
            )}
            {activeTab === 'Affiliate' && (
              <div>
                {/* <h3 className="text-lg font-semibold">Affiliate Info</h3> */}
                {/* <p>Coming Soon...</p> */}
                <button
                  className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
                  onClick={() => navigate('/user/affiiliatedashboard')}
                >
                  Affiliate Dashboard
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardNew;
