import React, { useEffect, useState } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, CircularProgress, Snackbar } from "@mui/material";
import { db } from "../../components/firebase";
import { doc, getDoc } from "firebase/firestore";
import { isUserLoggedIn } from "../../utils/authUtils";

const AffilateDashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productsToDisplay, setProductsToDisplay] = useState(10);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
    const [isUserLoggedInState, setIsUserLoggedInState] = useState(null); // State to track user login status


    // Fetch data from Firestore based on tracking-id, month, and year
    useEffect(() => {
        const fetchData = async () => {
            if (!isUserLoggedIn()) {
                // User is not logged in or session expired
                setIsUserLoggedInState(false);
                return;
            }
            document.title = "Affiliate Dashboard";
            try {
                const userData = localStorage.getItem('user');
                const { partnerTag } = JSON.parse(userData);
                const trackingId = partnerTag; // Retrieve tracking-id from localStorage
                
                if (!trackingId) {
                  console.error("No tracking-id found in localStorage.");
                  return;
                }

                // Format the month to two digits (01, 02, ..., 12)
                const formattedMonth = selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth;
                const docRef = doc(db, "TrackingData", trackingId, `${selectedYear}`, `${formattedMonth}`);
                const docSnapshot = await getDoc(docRef);  // Using getDoc instead of getDocs

                if (!docSnapshot.exists()) {
                  console.log("No matching document found in Firestore.");
                  return;
                }

                const productsData = docSnapshot.data().products;
                let allData = [];
                let earnings = 0;
                let products = 0;

                // Process the products
                if (productsData && Array.isArray(productsData) && productsData.length > 0) {
                  productsData.forEach((product) => {
                    allData.push({
                      date: product.dateShipped,
                      name: product.name.substring(0, 29),  // Shorten name to 29 characters
                      price: product.price,
                      earnings: Math.floor(product.earnings), // Display earnings as an integer
                      status: new Date(product.dateShipped) < new Date(new Date().setMonth(new Date().getMonth() - 2)) ? 'Confirmed' : 'Pending',
                    });
                    earnings += product.earnings;
                    products += 1;
                  });
                }

                // Sort data by date in descending order (latest date first)
                allData.sort((a, b) => new Date(b.date) - new Date(a.date));

                setData(allData);
                setTotalEarnings(Math.floor(earnings));  // Total earnings as integer
                setTotalProducts(products);
                setLoading(false);
              } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
              }
        };

        fetchData();
    }, [selectedMonth, selectedYear]); // Fetch data when the month or year changes

    const handleDisplayChange = (event) => {
        setProductsToDisplay(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    // Format earnings to remove decimals
    const formatEarnings = (amount) => {
        return Math.floor(amount); // Remove decimals
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Trigger a success snackbar after data is saved (for example, when a user confirms their action)
    const handleSaveData = () => {
        // In real implementation, save or confirm the data
        setSnackbarMessage("Data saved successfully!");
        setSnackbarOpen(true);
    };

    return (
        <Box className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
            <Typography variant="h4" className="text-center mb-6 text-gray-800">User Earnings Dashboard</Typography>

            {loading ? (
                <Box className="flex justify-center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {/* Earnings Summary */}
                    <Box className="mb-6">
                        <Typography variant="h6" className="text-gray-700">Total Earnings: ₹{formatEarnings(totalEarnings)}</Typography>
                        <Typography variant="h6" className="text-gray-700">Total Products Sold: {totalProducts}</Typography>
                    </Box>

                    {/* Month and Year Select - Side by side layout, responsive design */}
                    <Box className="flex flex-wrap justify-between mb-6">
                        <Box className="mr-4 mb-4 w-full sm:w-auto">
                            <Typography variant="body1" className="text-gray-600">Select Month: </Typography>
                            <Select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                displayEmpty
                                inputProps={{ "aria-label": "Month selection" }}
                                className="w-full sm:w-36 mt-2"
                            >
                                {[...Array(12).keys()].map((month) => (
                                    <MenuItem key={month} value={month + 1}>
                                        {new Date(0, month).toLocaleString('default', { month: 'long' })}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                        <Box className="mr-4 mb-4 w-full sm:w-auto">
                            <Typography variant="body1" className="text-gray-600">Select Year: </Typography>
                            <Select
                                value={selectedYear}
                                onChange={handleYearChange}
                                displayEmpty
                                inputProps={{ "aria-label": "Year selection" }}
                                className="w-full sm:w-36 mt-2"
                            >
                                {/* Add year options as needed */}
                                {[2024, 2023, 2022].map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </Box>

                        {/* Items per page select on the right */}
                        <Box className="w-full sm:w-auto">
                            <Typography variant="body1" className="text-gray-600">Items per Page:</Typography>
                            <Select
                                value={productsToDisplay}
                                onChange={handleDisplayChange}
                                displayEmpty
                                inputProps={{ "aria-label": "Items per page" }}
                                className="w-full sm:w-36 mt-2"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                                <MenuItem value={1000}>1000</MenuItem>
                            </Select>
                        </Box>
                    </Box>

                    {/* No Data Message if no data */}
                    {data.length === 0 && (
                        <Box className="flex justify-center mb-6">
                            <Typography variant="h6" className="text-gray-500">No data for this time.</Typography>
                        </Box>
                    )}

                    {/* Product Table */}
                    <TableContainer component={Paper} className="mb-6">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="font-bold">Date</TableCell>
                                    <TableCell className="font-bold">Name</TableCell>
                                    <TableCell className="font-bold">Price</TableCell>
                                    <TableCell className="font-bold">Earnings</TableCell>
                                    <TableCell className="font-bold">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(0, productsToDisplay).map((product, index) => (
                                    <TableRow key={index} className="border-t">
                                        <TableCell>{new Date(product.date).toLocaleDateString()}</TableCell>
                                        <TableCell>{product.name.slice(0, 29)}</TableCell>
                                        <TableCell>₹{product.price}</TableCell>
                                        <TableCell>₹{formatEarnings(product.earnings)}</TableCell>
                                        <TableCell>{product.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Save Data Button */}
                    <Box className="text-center">
                        <Button variant="contained" color="primary" onClick={handleSaveData}>Save Data</Button>
                    </Box>
                </>
            )}

            {/* Snackbar for success message */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default AffilateDashboard;
