import React, { useState, useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import { Snackbar, Alert } from "@mui/material";

const CollageCreator = ({ products }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const collageRef = useRef();
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility

  // Initially select all images
  useEffect(() => {
    setSelectedImages(products.map((product) => product.imageUrl));
  }, [products]);

  // Handle image selection
  const handleImageSelect = (imageUrl) => {
    if (selectedImages.includes(imageUrl)) {
      setSelectedImages(selectedImages.filter((img) => img !== imageUrl));
    } else if (selectedImages.length < 9) {
      setSelectedImages([...selectedImages, imageUrl]);
    }
  };

  // Generate the collage
  const createCollage = async () => {
    if (collageRef.current) {
      const canvas = await html2canvas(collageRef.current, { useCORS: true });
      return canvas.toDataURL("image/png");
    }
  };

  // Download the collage
  const downloadCollage = async () => {
    const dataUrl = await createCollage();
    if (dataUrl) {
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "dealskraft_Collage.png";
      link.click();
    }
  };

  // Copy the collage to the clipboard
  const copyCollage = async () => {
    const dataUrl = await createCollage();
    if (dataUrl) {
      const blob = await (await fetch(dataUrl)).blob();
      await navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
      setSnackbarOpen(true); // Show the Snackbar on success
    }
  };

  // Get grid class based on selectedImages length
  const getGridClass = () => {
    const layouts = {
      1: "grid-cols-1",
      2: "grid-cols-2",
      3: "grid-cols-3",
      4: "grid-cols-2",
      5: "grid-cols-3",
      6: "grid-cols-3",
      7: "grid-cols-4",
      8: "grid-cols-4",
      9: "grid-cols-3",
    };
    return layouts[selectedImages.length] || "grid-cols-3";
  };

  // Get grid styles for specified layouts
  const getGridStyles = (index) => {
    const stylesByCount = {
      2: [{ gridColumn: "span 1" }, { gridColumn: "span 1" }],
      3: [{ gridColumn: "span 1" }, { gridColumn: "span 1" }, { gridColumn: "span 1" }],
      4: [
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
      ],
      5: [
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 2" },
      ],
      6: [
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
      ],
      7: [
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 2" },
      ],
      8: [
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
      ],
      9: [
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
        { gridColumn: "span 1" },
      ],
    };
    return stylesByCount[selectedImages.length]?.[index] || {};
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Collage Creator</h2>
      <div className="grid grid-cols-9 gap-4 mb-4">
        {products.map((product, index) => (
          <div
            key={index}
            className={`p-2 border rounded-lg cursor-pointer ${selectedImages.includes(product.imageUrl) ? "border-blue-500" : "border-gray-300"}`}
            onClick={() => handleImageSelect(product.imageUrl)}
          >
            <img
              src={product.imageUrl}
              alt={product.title}
              className="w-10 h-10 object-cover"
            />
          </div>
        ))}
      </div>
      {selectedImages.length > 0 && (
        <div className="flex justify-center space-x-4 mt-4">
          <button
            onClick={downloadCollage}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Download Collage
          </button>
          <button
            onClick={copyCollage}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
          >
            Copy Collage
          </button>
        </div>
      )}
      <div
        ref={collageRef}
        className={`grid gap-2 bg-gray-100 p-4 ${getGridClass()}`}
      >
        {selectedImages.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Collage Image ${index + 1}`}
            className="w-full h-full object-contain"
            style={getGridStyles(index)}
          />
        ))}
      </div>

      

      {selectedImages.length === 0 && (
        <p className="text-gray-500 mt-4">Select up to 9 images to create a collage.</p>
      )}

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          Collage copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CollageCreator;
