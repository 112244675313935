import axios from 'axios';



const resolveLinksAndExtractASINs = async (inputValue) => {
    const urls = inputValue.split(',').map((url) => url.trim());
    const resolvedLinks = [];

    // Step 1: Resolve all short links using the provided API
    for (const url of urls) {
      try {
        const response = await axios.get(`https://dealcraft-api.vercel.app/resolve-link`, {
          params: { shortLink: url },
        });
        resolvedLinks.push(response.data.longLink); // Store resolved long links
      } catch (error) {
        console.error(`Error resolving link: ${url}`, error.message);
        // setSnackbarOpen(true);
        // setSnackbarMessage(`Error resolving link: ${url}`, error.message);
        // setSnackbarSeverity('error');
      }
    }

    // Step 2: Extract ASINs from resolved links
    const itemIds = resolvedLinks
      .map((url) => {
        // Extract ASIN using a regex
        const asinMatch = url.match(/(?:amazon\.[a-z\.]{2,3}\/(?:dp|gp\/product))\/([A-Z0-9]{10})/);
        return asinMatch ? asinMatch[1] : null;
      })
      .filter((asin, index) => {
        if (!asin) {
          console.error(`ASIN could not be extracted from the link: ${resolvedLinks[index]}`);
          return false; // Exclude invalid ASINs
        }
        return true; // Keep valid ASINs
      });

    console.log('Extracted ASINs:', itemIds);
    return itemIds;
  };

  const extractCategoryAndSubCategory2 = (browseNodes) => {
    let category = 'Uncategorized';
    let subCategory = 'Uncategorized';
  
    const traverse = (node) => {
      if (node) {
        category = node.DisplayName || 'Uncategorized'; // Set as top-level category
        if (node.Ancestor) {
          subCategory = category; // Move the current category to subcategory
          traverse(node.Ancestor); // Continue traversing for the top-level category
        }
      }
    };
  
    browseNodes?.forEach((node) => traverse(node));
    return { category, subCategory };
  };

  const extractCategoryAndSubcategory = (browseNodes) => {
    if (!browseNodes || browseNodes.length === 0) {
      return { category: "Uncategorized", subCategory: "Uncategorized" };
    }
  
    let category = null;
    let subCategory = null;
  
    const node = browseNodes[0]; // Only consider the first element
  
    // Traverse the hierarchy of the first node
    let currentNode = node;
  
    while (currentNode) {
      if (currentNode.DisplayName === "Categories") {
        // Set category when "Categories" is found
        category = currentNode.Ancestor?.ContextFreeName || "Uncategorized";
        
        break;
      }
      // Move to the ancestor
      subCategory = currentNode?.ContextFreeName || "Uncategorized";
      currentNode = currentNode.Ancestor;
    }
  
    return { category, subCategory };
  };

  export { resolveLinksAndExtractASINs, extractCategoryAndSubcategory };