import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../components/firebase"; // Firebase configuration
import { CircularProgress, Box } from "@mui/material";
import { CheckCircle } from '@mui/icons-material'; // Importing MUI icon (optional)
import { Typography, List, ListItem, ListItemText } from "@mui/material";


const ProductDetails = () => {
    const { id } = useParams(); // Get product ID from URL
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true); // State to track loading status
    const [userLink, setUserLink] = useState(null);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                // Step 1: Fetch ASIN ID from ProductIds table
                const productIdsRef = doc(db, "productIds", id);
                const productIdsSnap = await getDoc(productIdsRef);
                console.log("productIdsSnap:", productIdsSnap.data());
                if (productIdsSnap.exists()) {
                    const { asinId } = productIdsSnap.data();
                    const detailPageURL = productIdsSnap.data().userSpecificLink;
                    // Step 2: Fetch product details using ASIN ID from products table
                    const productRef = doc(db, "products", asinId);
                    const productSnap = await getDoc(productRef);

                    if (productSnap.exists()) {
                        const productData = productSnap.data();
                        console.log("productData:", productData);
                        setUserLink(detailPageURL);
                        setProduct(productData);
                    } else {
                        console.error("No product found with the given ASIN ID!");
                    }
                } else {
                    console.error("No product found with the given ID in ProductIds table!");
                }
            } catch (error) {
                console.error("Error fetching product details:", error);
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        fetchProductDetails();
    }, [id]);

    if (loading) {
        // Display loader while fetching
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!product) {
        // Handle case where product is not found after loading
        return (
            <div className="bg-gray-50 min-h-screen flex items-center justify-center">
                <p className="text-red-600 text-lg">Product not found!</p>
            </div>
        );
    }

    return (
        <div className="bg-gray-50 min-h-screen">
            <div className="max-w-7xl mx-auto p-6">
                {/* Product Container */}
                <div className="flex flex-col lg:flex-row items-start bg-white shadow-lg rounded-lg overflow-hidden">
                    {/* Image Section */}
                    <div className="w-full lg:w-1/2">
                        <img
                            src={product.imageUrl || "https://via.placeholder.com/500"}
                            alt={product.title || "Product Image"}
                            className="w-full h-auto object-cover"
                        />
                    </div>

                    {/* Details Section */}
                    <div className="w-full lg:w-1/2 p-6">
                        <h1 className="text-2xl font-bold text-gray-800">{product.title || "No Title"}</h1>
                        <p className="text-sm text-gray-600">{product?.category || "Uncategorized"}</p>
                        <p className="text-sm text-gray-400">{product?.subCategory || "Uncategorized"}</p>
                        <div className="mt-4">
                            <span className="text-xl font-semibold text-blue-600">₹{product.price || "N/A"}</span>
                            {product.originalPrice && (
                                <>
                                    <span className="text-sm line-through text-gray-400 ml-3">
                                        ₹{product.originalPrice}
                                    </span>
                                    <span className="text-sm text-green-500 ml-3">
                                        ({product.percentOff}% OFF)
                                    </span>
                                </>
                            )}
                        </div>
                        <p className="text-sm text-gray-500 mt-2">Inclusive of all taxes</p>

                        {/* Buttons */}
                        <div className="mt-6 flex gap-4">
                            {/* "BUY NOW" Button with onClick redirect */}
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-pink-700"
                                onClick={() => window.open(userLink, '_blank')}// Redirect on click
                            >
                                BUY NOW
                            </button>

                            {/* "WISHLIST" Button */}
                            <button className="bg-gray-100 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200">
                                WISHLIST
                            </button>
                        </div>


                    </div>
                </div>

                {/* Product Description */}
                {/* <div className="bg-white shadow-lg rounded-lg mt-6 p-6">
                    <h2 className="text-xl font-bold text-gray-800">Product Description</h2>
                    <p className="text-gray-600 mt-4">{product.description || "No description available."}</p>
                </div> */}

                {/* Features */}

                <div className="bg-white shadow-lg rounded-lg mt-6 p-6">
                    <Typography variant="h6" className="font-bold text-gray-800">
                        Features
                    </Typography>
                    <List className="mt-4">
                        {product.features && product.features.length > 0 ? (
                            product.features.map((feature, index) => {
                                const parts = feature.split(":");
                                return (
                                    <ListItem key={index} className="flex items-center">
                                        {/* Using emoji as bullet */}
                                        {/* <span className="mr-2 text-green-500 text-xl">✅</span> */}

                                        <ListItemText
                                            primary={<span className="font-semibold">{parts[0]}:</span>}
                                            secondary={<span className="text-gray-600">{parts[1]}</span>}
                                        />
                                    </ListItem>
                                );
                            })
                        ) : (
                            <ListItem>No features available.</ListItem>
                        )}
                    </List>
                </div>

            </div>
        </div>
    );
};

export default ProductDetails;
