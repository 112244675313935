import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box, CircularProgress } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../components/firebase'; // Ensure you've set up your Firebase
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../components/AuthContext';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth(); // Import login from useAuth


  // Check if the admin is already logged in and validate the login date
  useEffect(() => {
    const adminSession = sessionStorage.getItem('admin');
    if (adminSession) {
      const { isLoggedIn, loginDate } = JSON.parse(adminSession);
      const loginDateObj = new Date(loginDate);
      const currentDate = new Date();
      const twentyFourHoursAgo = new Date(currentDate.setHours(currentDate.getHours() - 24)); // 24 hours ago

      // If login date is older than 24 hours, clear session storage and redirect to login
      if (!isLoggedIn || loginDateObj < twentyFourHoursAgo) {
        sessionStorage.removeItem('admin');
        navigate('/admin-login');  // Redirect to admin login page
      } else {
        navigate('/admin-dashboard');  // If within 24 hours, redirect to admin dashboard
      }
    }
  }, [navigate]);

  const handleLogin = async () => {
    setLoading(true);
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the user is an admin in the Firestore database
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().role === 'admin') {
        // Save admin user state in sessionStorage
        const loginDate = new Date().toISOString(); // Get current date as login date

        sessionStorage.setItem('admin', JSON.stringify({
          uid: user.uid,
          isLoggedIn: true,
          loginDate: loginDate,
        }));
        login('admin');
        // Redirect to admin page
        navigate('/admin-dashboard');
      } else {
        setError('Not an admin');
      }
    } catch (error) {
      setError('Invalid credentials');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Typography variant="h5">Admin Login</Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginTop: 2 }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginTop: 2 }}
        />
        {error && <Typography color="error" sx={{ marginTop: 1 }}>{error}</Typography>}
        <Button
          variant="contained"
          fullWidth
          onClick={handleLogin}
          sx={{ marginTop: 3 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
      </Box>
    </Container>
  );
};

export default AdminLogin;
