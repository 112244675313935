import React, { useState, useEffect } from 'react';
import { db } from '../../components/firebase'; // Firebase configuration
import { collection, getDocs, updateDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import {
    Box,
    Card,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Icon for viewing

const Feedback = () => {
    const [feedbackList, setFeedbackList] = useState([]);
    const [users, setUsers] = useState({}); // Store users by ID in state
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [currentFeedback, setCurrentFeedback] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Fetch feedback and user details from Firestore
    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const feedbackSnapshot = await getDocs(collection(db, 'user-feedback'));
                const feedbackData = [];
                const userIds = new Set(); // Track unique user IDs

                feedbackSnapshot.docs.forEach((doc) => {
                    const feedback = doc.data();
                    feedbackData.push({ id: doc.id, ...feedback });
                    if (feedback.createdBy) {
                        userIds.add(feedback.createdBy);
                    }
                });

                // Fetch user details in parallel
                const userPromises = Array.from(userIds).map((userId) =>
                    getDoc(doc(db, 'users', userId))
                );

                const userDocs = await Promise.all(userPromises);
                const userMap = userDocs.reduce((acc, userDoc) => {
                    if (userDoc.exists()) {
                        acc[userDoc.id] = userDoc.data().name;
                    }
                    return acc;
                }, {});

                // Now set feedback list with user names from the userMap
                const updatedFeedbackData = feedbackData.map((feedback) => ({
                    ...feedback,
                    userName: userMap[feedback.createdBy] || 'Unknown',
                }));

                // Sort feedback list by date (newest first)
                updatedFeedbackData.sort((a, b) => b.date.seconds - a.date.seconds);

                setFeedbackList(updatedFeedbackData);
                setUsers(userMap); // Store the users in state
            } catch (error) {
                console.error('Error fetching feedback:', error);
                setSnackbarMessage('Error fetching feedback');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        };

        fetchFeedback();
    }, []);

    const handleViewFeedback = (feedback) => {
        setCurrentFeedback(feedback);
        setOpenViewDialog(true);

        // Mark feedback as viewed in Firestore
        if (!feedback.viewed) {
            updateDoc(doc(db, 'user-feedback', feedback.id), {
                viewed: true,
            }).then(() => {
                setFeedbackList((prevList) =>
                    prevList.map((item) =>
                        item.id === feedback.id
                            ? { ...item, viewed: true }
                            : item
                    )
                );
            }).catch((error) => {
                console.error('Error updating feedback:', error);
                setSnackbarMessage('Error marking feedback as viewed');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            });
        }
    };

    const handleDeleteFeedback = async (feedbackId) => {
        try {
            await deleteDoc(doc(db, 'user-feedback', feedbackId));
            setFeedbackList((prevList) =>
                prevList.filter((item) => item.id !== feedbackId)
            );
            setSnackbarMessage('Feedback deleted successfully');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error deleting feedback:', error);
            setSnackbarMessage('Error deleting feedback');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) {
            return 'No Date Available';
        }
        try {
            const date = timestamp.toDate();  // Firestore Timestamp
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        } catch (error) {
            console.error('Error formatting timestamp:', error);
            return 'Invalid Date';
        }
    };

    return (
        <Box sx={{ p: 4 }}>
            <Card sx={{ p: 4, mb: 3, boxShadow: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Feedback
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Here are the user feedbacks.
                </Typography>
            </Card>

            <TableContainer component={Paper} elevation={3}>
                <Table aria-label="feedback table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Given By</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedbackList.map((feedback) => (
                            <TableRow
                                key={feedback.id}
                                sx={{
                                    backgroundColor: feedback.viewed ? 'white' : '#f5f5f5', // Gray background for unviewed
                '& td': { fontWeight: feedback.viewed ? 'normal' : 'bold' }, // Make the entire row bold if unviewed
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                <TableCell>{feedback.title}</TableCell>
                                <TableCell>{feedback.userName}</TableCell>
                                <TableCell>
                                    {feedback.viewed ? 'Viewed' : 'New'}
                                </TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleViewFeedback(feedback)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <Button
                                        color="secondary"
                                        onClick={() => handleDeleteFeedback(feedback.id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* View Dialog */}
            <Dialog open={openViewDialog} onClose={() => setOpenViewDialog(false)}>
                <DialogTitle>{currentFeedback?.title}</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Given By: {currentFeedback?.userName}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {currentFeedback?.feedback}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <strong>Given on: </strong>{formatTimestamp(currentFeedback?.date)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenViewDialog(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for success/error messages */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Feedback;
