import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, type, ...rest }) => {
  const userSession = localStorage.getItem('user');  // For user authentication
  const adminSession = sessionStorage.getItem('admin');  // For admin authentication

  if (userSession) {
    const { uid, isLoggedIn, loginDate } = JSON.parse(userSession);
    const loginDateObj = new Date(loginDate);
    const currentDate = new Date();
    const threeDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 3)); // Get the date 3 days ago

    // Check if loginDate is within the past 3 days
    if (!isLoggedIn || loginDateObj < threeDaysAgo) {
      // If login date is outdated or the user is not logged in, clear session and redirect to login
      localStorage.removeItem('user');
      return <Navigate to="/user-login" replace />;
    }
  }

  if (adminSession) {
    const { uid, isLoggedIn, loginDate } = JSON.parse(adminSession);
    const loginDateObj = new Date(loginDate);
    const currentDate = new Date();
    const twentyFourHoursAgo = new Date(currentDate.setHours(currentDate.getHours() - 24)); // Get the date 24 hours ago

    // Check if loginDate is within the past 24 hours
    if (!isLoggedIn || loginDateObj < twentyFourHoursAgo) {
      // If login date is outdated or the admin is not logged in, clear session and redirect to login
      sessionStorage.removeItem('admin');
      return <Navigate to="/admin-login" replace />;
    }
  }

  // Check if the route is for admin and if the admin is logged in
  if (type === 'admin' && !adminSession) {
    return <Navigate to="/admin-login" replace />;
  }

  // Check if the route is for user and if the user is logged in
  if (type === 'user' && !userSession) {
    return <Navigate to="/user-login" replace />;
  }

  // If logged in and correct user type, render the element (component)
  return element;
};

export default ProtectedRoute;
