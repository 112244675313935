import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth, updatePassword } from 'firebase/auth';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import { useAuth } from './AuthContext';  // Assuming you have an auth context to get current user

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  
  const { currentUser } = useAuth();  // Assuming you are using Auth context to get the current user

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!newPassword || !confirmPassword) {
      setError("Please fill out both fields.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    setError('');

    try {
      // Get Firebase Authentication instance
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        // Update the password using Firebase Authentication
        await updatePassword(user, newPassword);

        // Optionally, update other user data in Firestore (if needed)
        const db = getFirestore();
        const userRef = doc(db, 'users', user.uid);
        
        // Example: Update user profile or other data in Firestore
        await updateDoc(userRef, {
            password: newPassword,  // Store the encrypted password
            passwordUpdated: new Date(),  // Track when the password was last updated
          });

        // Password reset successful
        setSuccess(true);

        // Redirect to the dashboard after a short delay
        setTimeout(() => navigate('/user-dashboard'), 3000);
      } else {
        setError("User not found.");
      }
    } catch (err) {
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 5,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: 'background.paper',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Reset Password
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>Password reset successfully! Redirecting...</Alert>}

      <form onSubmit={handlePasswordReset}>
        <TextField
          label="New Password"
          type="password"
          fullWidth
          required
          sx={{ mb: 2 }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          required
          sx={{ mb: 2 }}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Reset Password
        </Button>
      </form>
    </Box>
  );
};

export default ResetPassword;
