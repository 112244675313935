import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../components/firebase';
import { setDoc, doc, Timestamp } from 'firebase/firestore';
// import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import bcrypt from 'bcryptjs';

const AdminPanel = ({ onAdd }) => {
  const [users, setUsers] = useState([]); // To store the list of users
  const [open, setOpen] = useState(false); // To control the dialog visibility
  const [newUser, setNewUser] = useState({
    email: '',
    name: '',
    role: 'user',
    password: 'defaultPassword123',
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
    PartnerTag: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle field changes for new user
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Create new user in Firebase Authentication and Firestore
  const createUser = async () => {
    const { email, name, role, password, startDate, endDate, PartnerTag } = newUser;
    if (!email || !name) {
      setError('All fields are required');
      return;
    }
    const emailLowerCase = email.toLowerCase();


    setLoading(true);
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("user:", user);
      const saltRounds = 10; // You can adjust salt rounds (higher is more secure but slower)
      const hashedPassword = await bcrypt.hash(password, saltRounds);

      await setDoc(doc(db, 'users', emailLowerCase), {
        email: emailLowerCase,
        name,
        role,
        password,
        hashedPassword: hashedPassword,
        createdBy: JSON.parse(sessionStorage.getItem('admin')).uid,
        createdAt: new Date(),
        loginCount: 0,
        startDate: Timestamp.fromDate(startDate),
        endDate: Timestamp.fromDate(endDate),
        agreeTnC: false,
        PartnerTag,
        productIds: {},
      });

      onAdd(); // Reload users after creation
      setOpen(false); // Close dialog after successful user creation
      setNewUser({
        email: '',
        name: '',
        role: 'user',
        PartnerTag: '',
        password: 'defaultPassword123',
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
      });
      setError('');
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Failed to create user');
    } finally {
      setLoading(false);
    }
  };

  // Load users from Firestore (implement actual data loading here)
  const loadUsers = () => {
    onAdd();
  };

  return (
    <div>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Add New User
      </Button>

      {/* Add User Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            fullWidth
            margin="dense"
            variant="standard"
            value={newUser.email}
            name="email"
            onChange={handleFieldChange}
          />
          <TextField
            label="Name"
            fullWidth
            margin="dense"
            variant="standard"
            value={newUser.name}
            name="name"
            onChange={handleFieldChange}
          />
          <TextField
            label="Partner Tag"
            fullWidth
            margin="dense"
            variant="standard"
            value={newUser.PartnerTag}
            name="PartnerTag"
            onChange={handleFieldChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select
              value={newUser.role}
              name="role"
              onChange={handleFieldChange}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="no-access">No Access</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Password"
            fullWidth
            margin="dense"
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            value={newUser.password}
            name="password"
            onChange={handleFieldChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Start Date"
            fullWidth
            margin="dense"
            type="date"
            value={newUser.startDate.toISOString().slice(0, 10)}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setNewUser({ ...newUser, startDate: new Date(e.target.value) })}
          />
          <TextField
            label="End Date"
            fullWidth
            margin="dense"
            type="date"
            value={newUser.endDate.toISOString().slice(0, 10)}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setNewUser({ ...newUser, endDate: new Date(e.target.value) })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
          <Button onClick={createUser} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Create User'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminPanel;
