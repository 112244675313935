import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase'; // Replace with your Firebase config file
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const DeleteAccount = ({ userId }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [modalOpen, setModalOpen] = useState(false);

  // Open confirmation modal
  const handleOpenModal = () => setModalOpen(true);

  // Close confirmation modal
  const handleCloseModal = () => setModalOpen(false);

  // Handle delete request
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    try {
      // Add the delete request to Firestore
      await addDoc(collection(db, 'user-delete-request'), {
        userId, // The ID of the user requesting deletion
        requestDate: new Date(),
      });
      setSnackbarMessage('Your account deletion request has been sent.');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error submitting delete request:', error);
      setSnackbarMessage('An error occurred while submitting your request.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setDeleteLoading(false);
      setModalOpen(false);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <div className="flex flex-col items-center">
      {/* Delete Button */}
      <button
        onClick={handleOpenModal}
        className="bg-red-600 w-11/12 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
      >
        Delete Account
      </button>

      {/* Confirmation Modal */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <div className="bg-white p-6 rounded shadow-lg w-96 mx-auto mt-20">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Confirm Account Deletion</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete your account? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                disabled={deleteLoading}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteRequest}
                className="px-4 py-2 bg-red-600 text-white rounded flex items-center justify-center gap-2"
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <div className="w-5 h-5 border-4 border-t-4 border-white border-opacity-50 rounded-full animate-spin"></div>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* Snackbar for Feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default DeleteAccount;
