import React from 'react';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TemplateGeneratorPage from './pages/TemplateGeneratorPage';
import LandingPage from './pages/LandingPage';
import AdminDashboard from './pages/AdminDashboard';
import AdminLogin from './pages/AdminLogin';
import UserLogin from './pages/UserLogin';
import UserDashboard from './pages/UserDashboard';
import AboutUs from './components/AboutUs';
import Header from './components/Header';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import ResetPassword from './components/ResetPassword';
import FeatureSuggestions from './pages/admin/FeatureSuggestions';
import AccountDeletion from './pages/admin/AccountDeletion';
import Feedback from './pages/admin/Feedback';
import Users from './pages/admin/Users';
import Brands from './pages/admin/Brands';
import UserBrand from './pages/user/brands';
import ApiTestingPage from './pages/ApiTestingPage';
import UserAddTemplate from './pages/user/UserAddTemplate';
import HomePage from './pages/affiliate/HomePage';
import Home from './pages/affiliate/Home';
import ProductListing from './pages/affiliate/ProductListing';
import ProductDetails from './pages/affiliate/ProductDetails';
import ProductPage from './pages/affiliate/dummy';
import LinkGeneratorPage from './pages/user/LinkGeneratorPage';
import ProfilePage from './pages/user/ProfilePage';
import UserTable from './pages/admin/UserCard';
import SignUpPage from './pages/Signup';
import AdminReviewPage from './components/AdminReviewPage';
import CsvUpload from './pages/admin/CsvUpload';
import AffilateDashboard from './pages/user/AffiliateDashboard';
import UserDashboardNew from './pages/user/UserDashboardNew';
import CollagePage from './pages/affiliate/CollagePage';

const App = () => {


    return (

        <Router>
            <Header />
            <div className='main-content'>
                <Routes>
                    <Route path="/landing" element={<LandingPage />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/templategenerator" element={<TemplateGeneratorPage />} />
                    {/* <Route path="/admin" element={<ProtectedRoute element={<AdminDashboard />} type="admin" />} /> */}
                    <Route path="/admin-dashboard" element={<AdminDashboard />} />
                    {/* Admin Protected Routes */}
                    <Route path="/admin/feature-suggestions" element={<ProtectedRoute element={<FeatureSuggestions />} type="admin" />} />
                    <Route path="/admin/account-deletion" element={<ProtectedRoute element={<AccountDeletion />} type="admin" />} />
                    <Route path="/admin/feedback" element={<ProtectedRoute element={<Feedback />} type="admin" />} />
                    <Route path="/admin/users" element={<ProtectedRoute element={<Users />} type="admin" />} />
                    <Route path="/admin/brands" element={<ProtectedRoute element={<Brands />} type="admin" />} />
                    <Route path="/admin/onboarduser" element={<ProtectedRoute element={<AdminReviewPage />} type="admin" />} />
                    <Route path="/admin/report" element={<ProtectedRoute element={<CsvUpload />} type="admin" />} />



                    <Route path="/user-dashboard" element={<ProtectedRoute element={<UserDashboard />} />} />
                    <Route path="/user/brands" element={<ProtectedRoute element={<UserBrand />} type="user" />} />
                    <Route path="/user/getitems" element={<ProtectedRoute element={<LinkGeneratorPage />} type="user" />} />
                    <Route path="/user/affiiliatedashboard" element={<ProtectedRoute element={<AffilateDashboard />} type="user" />} />
                    <Route path="/user/dashboard" element={<ProtectedRoute element={<UserDashboardNew />} type="user" />} />
                    <Route path="/user/makepost" element={<ProtectedRoute element={<CollagePage />} type="user" />} />

                    <Route path="/signup" element={<SignUpPage />} />

                    <Route path="/admin-login" element={<AdminLogin />} />
                    <Route path="/user-login" element={<UserLogin />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsAndConditions />} />
                    <Route path="/reset-password" element={<ResetPassword />} />




                    <Route path="/allProducts" element={<ProductListing />} />
                    <Route path="/product/:id" element={<ProductDetails />} />
                    <Route path="/api-testing" element={<ApiTestingPage />} />
                    <Route path="/UserAddTemplate" element={<UserAddTemplate />} />
                    <Route path="/dummy" element={<ProductPage />} />
                    <Route path="/dummyProfile" element={<ProfilePage />} />



                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
};

export default App;
