import React, { useState,useEffect } from 'react';
import { TextField, Button, IconButton, InputAdornment, Grid, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestore imports
import { db } from '../components/firebase'; // Import Firestore configuration
import { isUserLoggedIn } from '../utils/authUtils';

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // Can be 'success' or 'error'
  });

  const [isSigningUp, setIsSigningUp] = useState(false); // Loader state
  const [isSignedUp, setIsSignedUp] = useState(false); // Tracks if signup was successful
  const navigate = useNavigate();


  useEffect(() => {
    document.title = 'Sign Up';
    isUserLoggedIn()
    if (isUserLoggedIn()) {
      navigate('/user-dashboard');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = { name: '', email: '', password: '', phone: '' };
    let isValid = true;

    if (!formData.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!formData.email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long';
      isValid = false;
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
      isValid = false;
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Phone number must be exactly 10 digits';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSigningUp(true); // Show loader
      try {
        const docRef = doc(db, 'signup', formData.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Email already exists in Firestore
          const existingData = docSnap.data();
          setSnackbar({
            open: true,
            message: `⚠️ Already registered with this mail on ${existingData.registeredDate}. We are currently reviewing your details.`,
            severity: 'warning',
          });
        } else {
          // Email not found, proceed with registration
          const currentDate = new Date().toLocaleDateString();

          await setDoc(docRef, {
            name: formData.name,
            email: formData.email,
            password: formData.password, // Note: Storing plaintext passwords is not secure. Use Firebase Authentication instead.
            phone: formData.phone,
            registeredDate: currentDate,
            status: 'Pending',
          });

          setSnackbar({
            open: true,
            message: '🎉 Signed up successfully! Check your email or phone for updates.',
            severity: 'success',
          });
          setIsSignedUp(true); // Hide form and show success message
        }
      } catch (error) {
        setSnackbar({
          open: true,
          message: '❌ Something went wrong. Please contact support.',
          severity: 'error',
        });
        console.error('Error signing up user:', error);
      } finally {
        setIsSigningUp(false); // Hide loader
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const redirectToHome = () => {
    navigate('/');
  };

  const redirectToLogin = () => {
    navigate('/user-login');
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full sm:w-96">
        {isSignedUp ? (
          // Success Message After Signup
          <div className="text-center">
            <Typography variant="h5" gutterBottom>
              🎉 Hurray!
            </Typography>
            <Typography variant="body1" gutterBottom>
              You are successfully signed up! We will contact you soon via email or phone.
            </Typography>
            <div className="mt-4 space-y-2">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={redirectToHome}
              >
                Explore
              </Button>
              <Button
                variant="standard"
                color="primary"
                fullWidth
                size="large"
                onClick={redirectToLogin}
              >
                Got the update? Login
              </Button>
            </div>
          </div>
        ) : (
          // Signup Form
          <>
            <Typography variant="h5" align="center" gutterBottom>
              Sign Up
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    variant="standard"
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="standard"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    variant="standard"
                    fullWidth
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formData.password}
                    onChange={handleChange}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    variant="standard"
                    fullWidth
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isSigningUp}
                  >
                    {isSigningUp ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Sign Up'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignUpPage;
