import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [user, setUser] = useState(null); // Add user state

  // Initialize login status and user data from storage
  useEffect(() => {
    const userSession = localStorage.getItem('user');
    const adminSession = sessionStorage.getItem('admin');

    if (userSession) {
      const parsedUser = JSON.parse(userSession);
      setIsUserLoggedIn(parsedUser.isLoggedIn);
      setUser(parsedUser); // Set user details
    }

    if (adminSession) {
      const parsedAdmin = JSON.parse(adminSession);
      setIsAdminLoggedIn(parsedAdmin.isLoggedIn);
    }
  }, []);

  const login = (userType, userDetails) => {
    if (userType === 'user') {
      const userSession = JSON.parse(localStorage.getItem('user')) || {};
      const updatedUser = { ...userSession, ...userDetails, isLoggedIn: true };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      setIsUserLoggedIn(true);
      setUser(updatedUser); // Update user state
    } else if (userType === 'admin') {
      const adminSession = JSON.parse(sessionStorage.getItem('admin')) || {};
      sessionStorage.setItem('admin', JSON.stringify({ ...adminSession, isLoggedIn: true }));
      setIsAdminLoggedIn(true);
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    sessionStorage.removeItem('admin');
    setIsUserLoggedIn(false);
    setIsAdminLoggedIn(false);
    setUser(null); // Clear user state
  };

  return (
    <AuthContext.Provider value={{ isUserLoggedIn, isAdminLoggedIn, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
