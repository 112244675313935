import React, { useState, useEffect } from 'react';
import { db } from './firebase'; // Import Firestore config
import { collection, getDocs, doc, updateDoc, setDoc } from 'firebase/firestore';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Snackbar, Alert } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import bcrypt from 'bcryptjs'; // Hashing password

const AdminReviewPage = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    document.title = "Admin Review";  // Set the page title dynamically

    fetchSignUpRequests();
  }, []);

  const fetchSignUpRequests = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'signup'));
      const requestsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setRequests(requestsData);
    } catch (error) {
      console.error('Error fetching sign-up requests:', error);
      setSnackbar({ open: true, message: 'Error fetching sign-up requests', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async (request) => {
    try {
      const { email, name, password, registeredDate } = request;

      // Create user
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email, password);

      const saltRounds = 10;
      const hashedPassword = await bcrypt.hash(password, saltRounds);

      await setDoc(doc(db, 'users', email.toLowerCase()), {
        email: email.toLowerCase(),
        name,
        role: 'User',
        password,
        hashedPassword: hashedPassword,
        createdBy: JSON.parse(sessionStorage.getItem('admin')).uid,
        createdAt: new Date(),
        loginCount: 0,
        startDate: new Date(registeredDate),
        endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
        agreeTnC: false,
        PartnerTag: "",
        productIds: {},
      });

      // Update the request status to accepted and remove action buttons
      const requestDocRef = doc(db, 'signup', request.email);
      await updateDoc(requestDocRef, { 
        status: 'Accepted',
        actionTaken: true,  // Mark as accepted
      });

      setSnackbar({ open: true, message: 'User successfully created and request accepted!', severity: 'success' });
      fetchSignUpRequests(); // Refresh requests after action
    } catch (error) {
      console.error('Error accepting the request:', error);
      setSnackbar({ open: true, message: 'Error accepting the request', severity: 'error' });
    }
  };

  const handleReject = async (request) => {
    try {
      // Update the request status to rejected
      const requestDocRef = doc(db, 'signup', request.email);
      await updateDoc(requestDocRef, { status: 'Rejected' });

      setSnackbar({ open: true, message: 'Request rejected successfully', severity: 'error' });
      fetchSignUpRequests(); // Refresh requests after action
    } catch (error) {
      console.error('Error rejecting the request:', error);
      setSnackbar({ open: true, message: 'Error rejecting the request', severity: 'error' });
    }
  };

  const handleHold = async (request) => {
    try {
      // Update the request status to on hold
      const requestDocRef = doc(db, 'signup', request.email);
      await updateDoc(requestDocRef, { status: 'On hold' });

      setSnackbar({ open: true, message: 'Request placed on hold', severity: 'info' });
      fetchSignUpRequests(); // Refresh requests after action
    } catch (error) {
      console.error('Error holding the request:', error);
      setSnackbar({ open: true, message: 'Error placing request on hold', severity: 'error' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className="p-8">
      <h2 className="text-3xl font-semibold mb-6 text-center">Admin Review Page</h2>
      
      {loading ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="font-semibold">Name</TableCell>
                <TableCell className="font-semibold">Email</TableCell>
                <TableCell className="font-semibold">Phone</TableCell>
                <TableCell className="font-semibold">Status</TableCell> {/* New Status Column */}
                <TableCell className="font-semibold">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.name}</TableCell>
                  <TableCell>{request.email}</TableCell>
                  <TableCell>{request.phone}</TableCell>
                  <TableCell>{request.status}</TableCell> {/* Display the current status */}
                  <TableCell>
                    {request.status !== 'accepted' && request.status !== 'rejected' ? ( // Only show buttons if the request is not accepted or rejected
                      <>
                        <Button
                          onClick={() => handleAccept(request)}
                          variant="contained"
                          color="primary"
                          className="mr-2"
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={() => handleHold(request)}
                          variant="outlined"
                          color="secondary"
                          className="mr-2"
                        >
                          Hold
                        </Button>
                        <Button
                          onClick={() => handleReject(request)}
                          variant="outlined"
                          color="error"
                        >
                          Reject
                        </Button>
                      </>
                    ) : (
                      <span>No Actions</span> // When request is accepted or rejected, show 'No Actions'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminReviewPage;
