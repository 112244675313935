import React from 'react';
import { Container, Typography, Divider, Box, Link } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ bgcolor: '#f9fafb', p: 6, borderRadius: 3, boxShadow: 3, mt: 2 }}>
      <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: '#333', mb: 3 }}>
        Privacy Policy for Deal Craft
      </Typography>
      <Typography variant="subtitle1" sx={{ color: '#555', mb: 4, textAlign: 'center' }}>
        Effective Date: <strong>[23 November 2024]</strong>
      </Typography>
      <Divider sx={{ mb: 4 }} />

      {/** Section 1 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          1. Introduction
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          Welcome to Deal Craft! Your privacy is a top priority for us. This Privacy Policy explains how we collect, use, and protect your personal information when you interact with our website and services. By accessing or using Deal Craft, you agree to the practices outlined in this Privacy Policy.
        </Typography>
      </Box>

      {/** Section 2 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          We collect different types of information to improve our services and provide a seamless experience:
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Personal Information:</strong> Includes your name, email address, and contact details provided during account registration or communication with us.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Usage Data:</strong> Information such as your IP address, browser type, device identifiers, and interaction history with Deal Craft.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Cookies and Tracking:</strong> We use cookies and similar technologies to enhance your experience, remember preferences, and collect analytics.
        </Typography>
      </Box>

      {/** Section 3 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          We use your data responsibly to:
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Operate and Improve Services:</strong> Provide platform functionality, customer support, and develop new features.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Communicate with You:</strong> Send updates, promotional materials (if opted in), and respond to inquiries.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Analyze Performance:</strong> Evaluate usage patterns to optimize platform performance and user experience.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Ensure Security:</strong> Detect and prevent fraudulent activity, unauthorized access, and other security risks.
        </Typography>
      </Box>

      {/** Section 4 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          We share your information only as necessary and with trusted partners:
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Service Providers:</strong> With third-party vendors for analytics, payment processing, and data storage, subject to strict confidentiality agreements.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Legal Requirements:</strong> To comply with applicable laws, court orders, or regulatory requirements.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Business Transfers:</strong> In case of a merger, acquisition, or sale, your data may transfer to the new owner.
        </Typography>
      </Box>

      {/** Section 5 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          5. Data Security
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          We use industry-standard measures to safeguard your data. While no system is 100% secure, we continuously monitor and enhance our security protocols to minimize risks of unauthorized access or breaches.
        </Typography>
      </Box>

      {/** Section 6 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          6. Your Choices and Rights
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          You have control over your data, including:
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Access and Correction:</strong> Request to review or correct your personal data.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Data Deletion:</strong> Request deletion of your personal information, where legally permissible.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Marketing Preferences:</strong> Opt-out of promotional emails by using the unsubscribe link or contacting us.
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>
          <strong>Cookie Management:</strong> Adjust your cookie settings through your browser preferences.
        </Typography>
      </Box>

      {/** Section 7 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          7. Third-Party Links
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          Deal Craft may link to third-party websites or services. We are not responsible for their privacy practices and recommend reviewing their policies before engaging with them.
        </Typography>
      </Box>

      {/** Section 8 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          8. Children's Privacy
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          Our platform is not designed for children under 13 years of age. If we learn that we have collected personal information from children under 13, we will delete it promptly.
        </Typography>
      </Box>

      {/** Section 9 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          9. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. Updates will be posted on this page with the revised effective date.
        </Typography>
      </Box>

      {/** Section 10 */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#444', mb: 1 }}>
          10. Contact Us
        </Typography>
        <Typography variant="body1" sx={{ color: '#666' }}>
          If you have questions, concerns, or need assistance regarding this Privacy Policy, please reach out to us at{' '}
          <Link href="mailto:privacy@dealcraft.com" sx={{ color: 'primary.main', textDecoration: 'none' }}>
            privacy@dealcraft.com
          </Link>.
        </Typography>
      </Box>

      <Divider sx={{ my: 4 }} />
      <Typography variant="body2" sx={{ textAlign: 'center', color: '#888' }}>
        Effective Date: <strong>[Insert Date]</strong>
      </Typography>
    </Container>
  );
}

export default PrivacyPolicy;
