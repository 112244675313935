import { useNavigate } from 'react-router-dom';

const UserToolTab = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center gap-1">
      <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/templategenerator')}
      >
        Go to Template Generator
      </button>
      <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/user/getitems')}
      >
        Create Deals
      </button>
      <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/user/makepost')}
      >
        Make Post
      </button>
      {/* <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/show-products')}
      >
        Show Products
      </button> */}
      <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/user/brands')}
      >
        Show Brands
      </button>
      {/* <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/dummy')}
      >
        Dummy
      </button> */}
    </div>
  );
};

export default UserToolTab;
