// EditPassword.js
import React, { useState } from 'react';
import { Snackbar, Alert, CircularProgress } from '@mui/material';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Replace with your Firebase config file
import bcrypt from 'bcryptjs';


const EditPassword = ({ userId }) => {
  // const [userId , setUserId] = useState(userId);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reenterPassword, setReenterPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');


  

  const handlePasswordChange = async () => {
    setPasswordError(''); // Clear previous errors
  
    if (newPassword !== reenterPassword) {
      setPasswordError("New password and re-entered password don't match.");
      return;
    }
  
    setLoading(true); // Show loading spinner
  
    try {
      // Reauthenticate the user with the current password
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User is not authenticated.');
      }
  
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
  
      // Update the user's password in Firebase Auth
      await updatePassword(user, newPassword);
      const saltRounds = 10;
      const hashedPassword = await bcrypt.hash(newPassword, saltRounds);
      // Update the password in Firestore (optional and depends on your structure)
      const userDocRef = doc(db, 'users', userId.userId); // Firestore reference to the user's document
      if (userDocRef && userId) {
        // Update Firestore with hashed password and lastPasswordChange timestamp
        await updateDoc(userDocRef, {
          hashedPassword: hashedPassword, // Save the hashed password
          lastPasswordChange: new Date(),
          password: newPassword,
        });
      } else {
        throw new Error('Invalid document reference or userId');
      }  
      // Show success feedback and clear inputs
      setSnackbarOpen(true); // Show success snackbar
      setSnackbarMessage('Password updated successfully!');
      setSnackbarSeverity('success');
      setShowModal(false); // Close modal
      setCurrentPassword(''); // Clear form fields
      setNewPassword('');
      setReenterPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
  
      // Handle errors
      if (error.code === 'auth/invalid-credential') {
        setPasswordError('Current password is incorrect.');
      } else if (error.code === 'auth/weak-password') {
        setPasswordError('New password is too weak. Please use a stronger password.');
      } else {
        setPasswordError('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setCurrentPassword('');
    setNewPassword('');
    setReenterPassword('');
    setPasswordError('');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="flex flex-col items-center">
      <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => setShowModal(true)}
      >
        Edit Password
      </button>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
            <h2 className="text-xl font-semibold mb-4">Change Password</h2>

            <input
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full p-2 border rounded-md mb-3 border-gray-300"
            />

            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-2 border rounded-md mb-3 border-gray-300"
            />

            <input
              type="password"
              placeholder="Re-enter New Password"
              value={reenterPassword}
              onChange={(e) => setReenterPassword(e.target.value)}
              className={`w-full p-2 border rounded-md mb-3 ${
                passwordError ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {passwordError && (
              <p className="text-red-500 text-sm mb-2">{passwordError}</p>
            )}

            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 border border-gray-400 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handlePasswordChange}
                disabled={loading}
                className="px-4 py-2 bg-blue-600 text-white rounded-md flex items-center"
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" className="mr-2" />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditPassword;
