import React from 'react';
import { Modal, Button, Typography } from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from './firebase';

const DeleteUserConfirmation = ({ userId, onClose, onDelete }) => {
    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, 'users', userId));
            onClose();
            onDelete();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    return (
        <Modal open={!!userId} onClose={onClose} closeAfterTransition>
            <div className="flex justify-center items-center min-h-screen bg-gray-600 bg-opacity-50">
                <div className="bg-white rounded-lg p-6 w-full max-w-sm shadow-lg">
                    <Typography variant="h6" className="text-xl font-semibold mb-4">
                        Are you sure you want to delete this user?
                    </Typography>
                    <div className="flex justify-between">
                        <Button onClick={onClose} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="error" variant="contained">
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteUserConfirmation;
