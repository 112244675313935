import React from "react";


const ProductCardList = ({ product, handleViewProduct }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Product Image */}
      <img
        src={product.imageUrl || "default-image.jpg"} // Fallback image if none provided
        alt={product.title || "Product Image"}
        className="w-full h-48 object-contain"
      />

      {/* Product Details */}
      <div className="p-4">
        {/* Title */}
        <h2 className="text-lg font-semibold text-gray-800 truncate w-full overflow-hidden">
          {product.title || "No Title Available"}
        </h2>

        {/* Pricing Section */}
        <div className="flex justify-between items-center mt-4">
          <p className="text-gray-500 text-sm line-through">
            Rs. {product.originalPrice || "N/A"}
          </p>
          <p className="text-red-600 text-base font-bold">
            Rs. {product.price || "N/A"}{" "}
            {product.percentOff && (
              <span className="text-green-500 text-sm">
                ({product.percentOff}% OFF)
              </span>
            )}
          </p>
        </div>

        {/* View Details Button */}
        <button
          className="mt-4 w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
          onClick={() => handleViewProduct(product.id)}
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default ProductCardList;
