import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { db } from "./firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const TermsAndConditionsPopup = () => {
  const { user, logout } = useAuth();
  const [agreeTnC, setAgreeTnC] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchAgreeTnC = async () => {
      if (user?.uid) {
        try {
          const userDocRef = doc(db, "users", user.uid); // Get reference to the document
          const userDoc = await getDoc(userDocRef); // Fetch document data
          if (userDoc.exists()) {
            setAgreeTnC(userDoc.data().agreeTnC || false);
            setShowPopup(!userDoc.data().agreeTnC); // Show popup only if agreeTnC is false
          }
        } catch (error) {
          console.error("Error fetching agreeTnC:", error);
        }
      }
    };

    fetchAgreeTnC();
  }, [user]);

  const handleAccept = async () => {
    if (user?.uid) {
      try {
        const userDocRef = doc(db, "users", user.uid); // Reference to the user's document
        await updateDoc(userDocRef, { agreeTnC: true }); // Update the agreeTnC field
        setAgreeTnC(true);
        setShowPopup(false); // Close popup after accepting
      } catch (error) {
        console.error("Error updating agreeTnC:", error);
      }
    }
  };

  const handleReject = () => {
    logout(); // Log out the user if they reject the terms
  };

  if (!showPopup) {
    return null; // Do not render the popup if agreeTnC is true
  }

    return (
        <>
            {/* Main Popup */}
            <Dialog open={open} disableEscapeKeyDown>
                <DialogTitle>Accept Terms and Conditions</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Quick Summary of Terms and Conditions for Trial Users
                    </Typography>
                    <ol>
                        <li>Personal Use Only: Do not share your credentials or access with others.</li>
                        <li>No Unauthorized Sharing: Contact us if others want access.</li>
                        <li>Trial Access Only: Services may change or stop without notice.</li>
                        <li>Termination for Violations: Misuse may result in immediate ban.</li>
                        <li>Proprietary Content: Copying or reverse engineering is prohibited.</li>
                        <li>No Guarantees: Provided as-is with no guarantees.</li>
                    </ol>
                    <Button
                        variant="outlined"
                        onClick={() => setShowDetails(true)}
                        sx={{ mt: 2 }}
                    >
                        View Detailed Terms and Conditions
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReject} color="error">
                        Reject and Logout
                    </Button>
                    <Button onClick={handleAccept} color="primary">
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Detailed Terms and Conditions Popup */}
            <Dialog open={showDetails} onClose={() => setShowDetails(false)}>
                <DialogTitle>Detailed Terms and Conditions</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" paragraph>
                        <strong>Effective Date:</strong> [Insert Date]
                    </Typography>
                    <Typography variant="body2" paragraph>
                        1. <strong>Acceptance of Terms:</strong> By accessing and using Deal Craft as a trial user, you agree to be bound by these Terms and Conditions. If you disagree with any of these terms, you must discontinue use of the platform immediately.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        2. <strong>Nature of the Service:</strong> Deal Craft is currently in its development phase and is being provided to you on a trial basis. The trial access is offered as-is, with no guarantees of uninterrupted or error-free functionality.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        3. <strong>User Responsibilities:</strong>
                        <ul>
                            <li>Personal Use Only: The trial account is provided strictly for your personal use. Sharing your login credentials with others is strictly prohibited.</li>
                            <li>Prohibited Sharing: You are not permitted to share access to the platform, its templates, or any proprietary tools with any other individual or entity.</li>
                            <li>Violation Consequences: Violating these terms may result in the immediate termination of your trial access and potential legal action.</li>
                        </ul>
                    </Typography>
                    <Typography variant="body2" paragraph>
                        4. <strong>Invitations to Use Deal Craft:</strong> If you wish to recommend Deal Craft to others, you must contact us directly at [contact email]. We will assess the feasibility of onboarding additional users at our discretion. Unauthorized use or third-party access is not permitted and may result in suspension or banning of your account.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        5. <strong>Platform Changes and Service Termination:</strong> Deal Craft reserves the right to modify, suspend, or terminate the trial service without prior notice. However, we will provide a formal notice before the conclusion of your trial period to ensure a smooth transition.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        6. <strong>Disclaimer of Warranties:</strong> The trial access is provided on an "as-is" and "as-available" basis. We make no representations or warranties, express or implied, regarding the platform's availability, reliability, or functionality. Deal Craft shall not be liable for any loss, damage, or inconvenience caused by interruptions or discontinuation of the trial service.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        7. <strong>Termination of Access:</strong> Deal Craft retains the right to revoke access to the platform at any time if these terms are violated or if it is deemed necessary for operational or developmental reasons. Upon termination, users must discontinue use of the platform and destroy any materials obtained during the trial period.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        8. <strong>Intellectual Property Rights:</strong> All content, including templates, designs, and tools on Deal Craft, are the exclusive property of Deal Craft. Users are prohibited from copying, distributing, or reverse engineering any content or tools made available during the trial.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        9. <strong>Indemnity:</strong> You agree to indemnify and hold harmless Deal Craft and its team from any claims, damages, or losses arising from misuse of the platform or violation of these Terms and Conditions.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        10. <strong>Governing Law:</strong> These Terms are governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law provisions.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        11. <strong>Contact Us:</strong> For any questions or clarifications regarding these Terms, please contact us at support@DealsKraft.com.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDetails(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default TermsAndConditionsPopup;
