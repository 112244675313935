// adminDashboardAPI.js
import { db } from '../components/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

// Fetch brand metrics
export const getBrandMetrics = async () => {
  const brandsCollection = collection(db, 'brands');
  const totalBrands = await getDocs(brandsCollection);
  const userAddedBrands = await getDocs(query(brandsCollection, where('addedBy', '==', 'user')));
  const adminAddedBrands = await getDocs(query(brandsCollection, where('addedBy', '==', 'admin')));
  
  // Count recent brands (within the last 10 days)
  const recentDate = new Date();
  recentDate.setDate(recentDate.getDate() - 10);
  const recentBrands = await getDocs(query(brandsCollection, where('addedDate', '>=', recentDate)));
  
  return {
    addedByUsers: userAddedBrands.size,
    addedByAdmin: adminAddedBrands.size,
    recentlyAdded: recentBrands.size,
    total: totalBrands.size
  };
};

// Fetch user metrics
export const getUserMetrics = async () => {
  const usersCollection = collection(db, 'users');
  const allUsers = await getDocs(usersCollection);
  
  return {
    total: allUsers.size,
  };
};

// Fetch feedback metrics
export const getFeedbackMetrics = async () => {
  const feedbackCollection = collection(db, 'user-feedback');
  const allFeedback = await getDocs(feedbackCollection);
  const newFeedback = await getDocs(query(feedbackCollection, where('status', '==', 'new')));
  const reviewedFeedback = await getDocs(query(feedbackCollection, where('status', '==', 'reviewed')));
  
  return {
    total: allFeedback.size,
    new: newFeedback.size,
    reviewed: reviewedFeedback.size,
  };
};

// Fetch feature suggestion metrics
export const getFeatureSuggestionMetrics = async () => {
  const suggestionsCollection = collection(db, 'user-request-features');
  const allSuggestions = await getDocs(suggestionsCollection);
  const newSuggestions = await getDocs(query(suggestionsCollection, where('status', '==', 'new')));
  const activeSuggestions = await getDocs(query(suggestionsCollection, where('status', '==', 'active')));
  const savedForLaterSuggestions = await getDocs(query(suggestionsCollection, where('status', '==', 'savedForLater')));
  
  return {
    total: allSuggestions.size,
    new: newSuggestions.size,
    active: activeSuggestions.size,
    savedForLater: savedForLaterSuggestions.size,
  };
};

// Fetch account deletion requests metrics
export const getAccountDeletionRequests = async () => {
  const deletionRequestsCollection = collection(db, 'user-delete-request');
  const allRequests = await getDocs(deletionRequestsCollection);
  const pendingRequests = await getDocs(query(deletionRequestsCollection, where('status', '==', 'pending')));
  const completedRequests = await getDocs(query(deletionRequestsCollection, where('status', '==', 'completed')));
  
  return {
    total: allRequests.size,
    pending: pendingRequests.size,
    completed: completedRequests.size,
  };
};
