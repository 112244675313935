import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db } from "../../components/firebase"; // Adjust path as needed
import { doc, setDoc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";
import { CircularProgress, Tabs, Tab, Box, Grid, Button, TextField, Snackbar,Alert } from '@mui/material';
import ProductCard from '../../components/ProductCard';
import { isUserLoggedIn } from '../../utils/authUtils';
import { v4 as uuidv4 } from 'uuid';
import CollageCreator from '../../components/CollageCreator';
import ApiProductTemplate from '../../components/ApiProductTemplate';
import { nanoid } from 'nanoid';
import { resolveLinksAndExtractASINs,extractCategoryAndSubcategory } from '../../utils/ApiHelper';
import { addOrUpdateProductInCategory, saveProductsToFirestore } from '../../utils/DBHelper';

const LinkGeneratorPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [apiResult, setApiResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAsinMode, setIsAsinMode] = useState(true); // State to toggle between ASIN and Link input
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [isUserLoggedInState, setIsUserLoggedInState] = useState(null); // State to track user login status
  const [userId, setUserId] = useState(null);
  const [partnerTag, setPartnerTag] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');




  const currentUser = "user123"; // Replace this with the authenticated user's ID


  useEffect(() => {
    if (!isUserLoggedIn()) {
      // User is not logged in or session expired
      setIsUserLoggedInState(false);
      return;
    }

    // User is logged in, proceed to fetch user data
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUserId(storedUser?.uid || null);
    setPartnerTag(storedUser?.partnerTag || null);
    setIsUserLoggedInState(true);
  })
  // Handle tab changes
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const saveUserProductMapping = async (userId, asinId, userSpecificLink, item) => {
    try {
      // Log inputs for debugging
      console.log("userId:", userId);
      console.log("asinId:", asinId);
      console.log("userSpecificLink:", userSpecificLink);

      // Validate input parameters
      if (!userId || !asinId || !userSpecificLink) {
        console.error("Missing required parameters:", { userId, asinId, userSpecificLink });
        return;
      }

      const productId = nanoid(6); // Generate a 6-character unique ID
      const productData = {
        createdBy: userId,
        asinId: asinId,
        userSpecificLink: userSpecificLink,
        savedTimestamp: new Date().toISOString(),
        title: item.title,
        imageUrl: item.imageUrl,
        originalPrice: item.originalPrice,
        percentOff: item.percentOff,
        price: item.price
      };

      // Save product data to the "products" collection
      const productDocRef = doc(db, `productIds/${productId}`);
      await setDoc(productDocRef, productData);

      // Add the productId to the user's product list
      const userDocRef = doc(db, `users/${userId}`);
      await updateDoc(userDocRef, {
        [`productIds.${asinId}`]: productId
      });
      item.productId = productId;
      addOrUpdateProductInCategory(item.category, productId);
      console.log("after updating productIds:", item)
      console.log("Saved product with ID:", productId);
    } catch (error) {
      console.error("Error saving product for user:", error);
    }
  };
  // Save product details in Firestore with document name as ASIN
  // const saveProductsToFirestore = async (products) => {
  //   try {
  //     for (const product of products) {
  //       const productDocRef = doc(db, "products", product.asin);
  //       await setDoc(productDocRef, product, { merge: true });
  //     }
  //     console.log("Products saved to Firestore!");
  //   } catch (error) {
  //     console.error("Error saving products to Firestore:", error);
  //   }
  // };

  // const resolveLinksAndExtractASINs = async (inputValue) => {
  //   const urls = inputValue.split(',').map((url) => url.trim());
  //   const resolvedLinks = [];

  //   // Step 1: Resolve all short links using the provided API
  //   for (const url of urls) {
  //     try {
  //       const response = await axios.get(`https://dealcraft-api.vercel.app/resolve-link`, {
  //         params: { shortLink: url },
  //       });
  //       resolvedLinks.push(response.data.longLink); // Store resolved long links
  //     } catch (error) {
  //       console.error(`Error resolving link: ${url}`, error.message);
  //       setSnackbarOpen(true);
  //       setSnackbarMessage(`Error resolving link: ${url}`, error.message);
  //       setSnackbarSeverity('error');
  //     }
  //   }

  //   // Step 2: Extract ASINs from resolved links
  //   const itemIds = resolvedLinks
  //     .map((url) => {
  //       // Extract ASIN using a regex
  //       const asinMatch = url.match(/(?:amazon\.[a-z\.]{2,3}\/(?:dp|gp\/product))\/([A-Z0-9]{10})/);
  //       return asinMatch ? asinMatch[1] : null;
  //     })
  //     .filter((asin, index) => {
  //       if (!asin) {
  //         console.error(`ASIN could not be extracted from the link: ${resolvedLinks[index]}`);
  //         return false; // Exclude invalid ASINs
  //       }
  //       return true; // Keep valid ASINs
  //     });

  //   console.log('Extracted ASINs:', itemIds);
  //   return itemIds;
  // };
  const handleGetDetails = async () => {
    let itemIds = [];
    setIsLoading(true);
    if (isAsinMode) {
      // If in ASIN mode, use the entered ASINs directly
      itemIds = inputValue.split(',').map(id => id.trim());
    } else {
      itemIds = await resolveLinksAndExtractASINs(inputValue);
      console.log(itemIds);
    }
    if (itemIds.length === 0) {
      setError('Invalid product link(s). Please enter valid Amazon product links.');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Step 1: Check for existing products in Firestore
      const { missingIds, existingProducts } = await checkExistingProducts(userId, itemIds);
      console.log('Missing ASINs:', missingIds);
      console.log('Existing Products:', existingProducts);

      // Step 2: Handle existing products
      // You may want to display the existing products to the user
      if (existingProducts.length > 0) {
        setApiResult(existingProducts); // You can display existing products if needed
      }

      // Step 3: Fetch details for the missing products
      if (missingIds.length > 0) {
        let payload = {}
        if (partnerTag) {
          console.log("sending api request with partner tag", partnerTag);
          payload = { itemIds: missingIds, PartnerTag: partnerTag };
        } else {
          payload = { itemIds: missingIds };
        }
        
        const response = await axios.post('https://dealcraft-api.vercel.app/getitems', payload, {
          headers: { 'Content-Type': 'application/json' },
        });

        const items = response.data?.ItemsResult?.Items || [];
        /* const formattedItems = items.map(item => ({
          productId: null,
          asin: item.ASIN,
          title: item.ItemInfo.Title.DisplayValue,
          detailPageURL: item.DetailPageURL,
          imageUrl: item.Images.Primary.Large.URL,
          price: item.Offers.Listings[0].Price.Amount,
          originalPrice: item.Offers.Listings[0].Price.Amount + item.Offers.Listings[0].Price?.Savings?.Amount || 0,
          percentOff: item.Offers.Listings[0].Price.Savings?.Percentage || 0,
          savings: item.Offers.Listings[0].Price.Savings
            ? item.Offers.Listings[0].Price.Savings.DisplayAmount
            : 'No Discount',
          features: item.ItemInfo.Features?.DisplayValues || [],
          category: item.ItemInfo?.Classifications?.ProductGroup?.DisplayValue || "Uncategorized",
          binding: item.ItemInfo?.Classifications?.Binding?.DisplayValue || "Uncategorized",
        })); */

        const formattedItems = items.map(item => {
          const listing = item.Offers?.Listings?.[0]; // Safely access the first listing
          const price = listing?.Price?.Amount || 0; // Default to 0 if price is missing
          const savings = listing?.Price?.Savings || null; // Default to null if savings is missing
          const browseNodes = item.BrowseNodeInfo?.BrowseNodes || [];
          const { category, subCategory } = extractCategoryAndSubcategory(browseNodes);
          console.log("category", category, "subCategory", subCategory);
          return {
            productId: null,
            asin: item.ASIN,
            title: item.ItemInfo.Title?.DisplayValue || 'No Title Available',
            detailPageURL: item.DetailPageURL || '#',
            imageUrl: item.Images?.Primary?.Large?.URL || 'No Image URL Available',
            price: price,
            originalPrice: price + (savings?.Amount || 0), // Add savings amount if available
            percentOff: savings?.Percentage || 0,
            savings: savings?.DisplayAmount || 'No Discount',
            features: item.ItemInfo.Features?.DisplayValues || [],
            category: category,
            subCategory: subCategory,
            binding: item.ItemInfo?.Classifications?.Binding?.DisplayValue || 'Uncategorized',
          };
        });
        

        // Step 4: Save new products to Firestore and map them to the user
        for (const item of formattedItems) {
          await saveUserProductMapping(userId, item.asin, item.detailPageURL, item);
        }
        await saveProductsToFirestore(formattedItems);

        // Step 5: Combine the existing and new product data and display the result
        const allProducts = [...existingProducts, ...formattedItems];
        setApiResult(allProducts);
      }

    } catch (error) {
      setError("Failed to fetch product details.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Updated checkExistingProducts function to match new logic
  const checkExistingProducts = async (userId, asinArray) => {
    try {
      // Get the user's document
      const userDocRef = doc(db, `users/${userId}`);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        // Get the productIds dictionary from the user document
        const userProductIds = userData.productIds || {};

        const missingIds = [];
        const existingProducts = [];

        // Step 1: Loop through the ASIN array (incoming asinArray)
        for (const asin of asinArray) {
          // Check if the product is in the user's productIds dictionary
          const productId = userProductIds[asin];

          if (productId) {
            // If the productId exists for the ASIN, get the product document
            const productDocRef = doc(db, `productIds/${productId}`);
            const productDocSnap = await getDoc(productDocRef);

            if (productDocSnap.exists()) {
              // Get the product data
              const productData = await getProductByAsin(asin);
              const affURL = productDocSnap.data().userSpecificLink;

              // Flatten the product data and add asin and detailPageURL at the top level
              existingProducts.push({
                productId,
                asin,
                detailPageURL: affURL,
                title: productData.title,
                imageUrl: productData.imageUrl,
                price: productData.price,
                originalPrice: productData.originalPrice,
                percentOff: productData.percentOff,
                savings: productData.savings,
                features: productData.features,
                category: productData.category,
                binding: productData.binding,
              });
            }
          } else {
            // If the productId is missing, add the ASIN to missingIds
            missingIds.push(asin);
          }
        }

        return {
          missingIds,         // Return missing ASINs
          existingProducts,   // Return existing product data
        };

      } else {
        console.log("User not found!");
        return { missingIds: asinArray, existingProducts: [] };
      }

    } catch (error) {
      console.error("Error checking existing products:", error);
      return { missingIds: asinArray, existingProducts: [], error: error.message };
    }
  };




  const getProductByAsin = async (asin) => {
    try {
      // Step 1: Reference the product document using the ASIN as the document ID
      const productDocRef = doc(db, `products/${asin}`);
      const productDocSnap = await getDoc(productDocRef);

      // Step 2: Check if the product document exists
      if (productDocSnap.exists()) {
        const productData = productDocSnap.data();

        // Return the product data and URL if the product is found
        return productData;
      } else {
        // If the document doesn't exist, return found: false
        console.log(`No product found with ASIN ${asin}`);
        return [];
      }
    } catch (error) {
      console.error("Error retrieving product by ASIN:", error);
      return [];
    }
  };


  const handleToggle = () => {
    setIsAsinMode(!isAsinMode); // Toggle between ASIN and Link mode
    setInputValue(''); // Reset the input value when toggling
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">Link Generator</h1>

      {/* Toggle Buttons for ASIN and Link */}
      <div className="flex items-center justify-center mb-4 space-x-4">
        <button
          onClick={handleToggle}
          className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${isAsinMode
              ? "bg-blue-600 text-white hover:bg-blue-700"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
            }`}
        >
          ASIN
        </button>
        <button
          onClick={handleToggle}
          className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${!isAsinMode
              ? "bg-blue-600 text-white hover:bg-blue-700"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
            }`}
        >
          Link
        </button>
      </div>

      {/* Input Box */}
      <textarea
        value={inputValue}
        onChange={handleInputChange}
        placeholder={
          isAsinMode
            ? "Enter item IDs separated by commas (e.g., B0D7M4M611, B0CWYXPS59)"
            : "Enter full product link(s) (e.g., https://www.amazon.in/dp/B0CWYXPS59)"
        }
        className=" w-full max-w-lg p-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        rows={3}
      ></textarea>

      {/* Get Details Button */}
      <button
        onClick={handleGetDetails}
        disabled={isLoading}
        className={`px-6 py-2 text-sm font-semibold text-white rounded-lg mb-4 ${isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
          }`}
      >
        {isLoading ? "Fetching..." : "Get Details"}
      </button>

      {/* Error Message */}
      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}

      {/* MUI Button Example */}
      {/* <Button
        onClick={handleGetDetails}
        disabled={isLoading}
        variant="contained"
        color="primary"
        fullWidth
      >
        {isLoading ? <CircularProgress size={24} /> : "Fetch Product Details"}
      </Button> */}

      {/* Tabs for displaying content */}
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        centered
        variant="scrollable" // Allows horizontal scrolling for mobile
        scrollButtons="auto" // Automatically shows scroll buttons when needed
        className="mt-6"
      >
        <Tab label="Product Cards" />
        <Tab label="Collage Creator" />
        <Tab label="Product Template" />
      </Tabs>

      {/* Tab Panels */}
      <Box sx={{ paddingTop: 2 }}>
        {tabIndex === 0 && (
          <>
            {/* Display API Result as Cards */}
            <Grid container spacing={4} justifyContent="center">
              {isLoading ? (
                <div className="flex justify-center w-full">
                  <CircularProgress />
                </div>
              ) : (
                apiResult.map((item, index) => (
                  <Grid item key={index}>
                    <ProductCard product={item} />
                  </Grid>
                ))
              )}
            </Grid>
          </>
        )}

        {tabIndex === 1 && (
          <>
            {/* Collage Creator */}
            <CollageCreator products={apiResult} />
          </>
        )}
        {tabIndex === 2 && (
          <>
            {/* Product Template */}
            <ApiProductTemplate products={apiResult} />
          </>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Closes after 3 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};


export default LinkGeneratorPage;
