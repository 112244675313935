import { useNavigate } from 'react-router-dom';
import EditPassword from './EditPassword';
import DeleteAccount from './DeleteAccount';

const UserSupportTab = (userId) => {
  const navigate = useNavigate();

  return (
    <div className="grid gap-1 flex flex-col items-center">
                  <EditPassword userId={userId} />
        <div className='flex flex-col items-center'>
            <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/user/feedback')}
      >
        Give Feedback
      </button>
      <button
        className="w-11/12 bg-blue-500 p-3 rounded focus:outline-none hover:bg-gray-100 hover:text-black"
        onClick={() => navigate('/user/feature-suggestion')}
      >
        Feature Suggestion
      </button>
        </div>
      
      <DeleteAccount userId={userId} />
    </div>
  );
};

export default UserSupportTab;
