import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, Button, Grid, CircularProgress, Tabs, Tab, Box } from '@mui/material';
import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../components/firebase"; // Adjust path as needed
import ProductCard from '../components/ProductCard';
import CollageCreator from '../components/CollageCreator';
import ApiProductTemplate from '../components/ApiProductTemplate';



const ApiTestingPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [apiResult, setApiResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAsinMode, setIsAsinMode] = useState(true); // State to toggle between ASIN and Link input
  const [tabIndex, setTabIndex] = useState(0); // State to track the selected tab


  // Handle tab change
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleToggle = () => {
    setIsAsinMode(!isAsinMode); // Toggle between ASIN and Link mode
    setInputValue(''); // Reset the input value when toggling
  };

  // Function to check existing products in Firestore
  const getExistingProductsFromFirestore = async (itemIds) => {
    try {
      const docRef = doc(db, "products", "testing");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const existingProducts = docSnap.data()?.products || [];

        // Filter products that match the provided ASINs
        const foundProducts = existingProducts.filter((product) =>
          itemIds.includes(product.asin)
        );

        // Filter ASINs that are not found in Firestore
        const foundAsins = foundProducts.map((product) => product.asin);
        const missingProductIds = itemIds.filter((id) => !foundAsins.includes(id));

        return { foundProducts, missingProductIds };
      }

      // If no document exists, assume all ASINs are missing
      return { foundProducts: [], missingProductIds: itemIds };
    } catch (error) {
      console.error("Error fetching products from Firestore:", error);
      return { foundProducts: [], missingProductIds: itemIds }; // Assume all items are missing in case of error
    }
  };

  const saveProductsToFirestore = async (products) => {
    const docRef = doc(db, "products", "testing");

    try {
      const docSnap = await getDoc(docRef);

      const newProductData = products.map(item => {
        const binding = item.binding || "Unknown";

        return {
          asin: item.asin,
          title: item.title || "No Title",
          price: item.price || 0,
          imageUrl: item.imageUrl || "",
          detailPageURL: item.detailPageURL || "",
          category: item.category || "Unknown",
          binding: binding,
          features: item.features || [],
        };
      });

      if (docSnap.exists()) {
        const existingProducts = docSnap.data()?.products || [];
        const updatedProducts = [...existingProducts, ...newProductData];
        await updateDoc(docRef, { products: updatedProducts });
      } else {
        await setDoc(docRef, { products: newProductData });
      }

      console.log("Products saved to Firestore!");
    } catch (error) {
      console.error("Error saving products to Firestore:", error);
    }
  };

  const resolveLinksAndExtractASINs = async (inputValue) => {
    const urls = inputValue.split(',').map((url) => url.trim());
    const resolvedLinks = [];
  
    // Step 1: Resolve all short links using the provided API
    for (const url of urls) {
      try {
        const response = await axios.get(`https://dealcraft-api.vercel.app/resolve-link`, {
          params: { shortLink: url },
        });
        resolvedLinks.push(response.data.longLink); // Store resolved long links
      } catch (error) {
        console.error(`Error resolving link: ${url}`, error.message);
      }
    }
  
    // Step 2: Extract ASINs from resolved links
    const itemIds = resolvedLinks
      .map((url) => {
        // Extract ASIN using a regex
        const asinMatch = url.match(/(?:amazon\.[a-z\.]{2,3}\/(?:dp|gp\/product))\/([A-Z0-9]{10})/);
        return asinMatch ? asinMatch[1] : null;
      })
      .filter((asin, index) => {
        if (!asin) {
          console.error(`ASIN could not be extracted from the link: ${resolvedLinks[index]}`);
          return false; // Exclude invalid ASINs
        }
        return true; // Keep valid ASINs
      });
  
    console.log('Extracted ASINs:', itemIds);
    return itemIds;
  };

  const handleGetDetails = async () => {
    let itemIds = [];

    if (isAsinMode) {
      // If in ASIN mode, use the entered ASINs directly
      itemIds = inputValue.split(',').map(id => id.trim());
    } else {
      // If in Link mode, extract ASINs from entered URLs
      /* itemIds = inputValue.split(',').map(url => {
        // Extract ASIN using a regex
        const asinMatch = url.match(/(?:amazon\.[a-z\.]{2,3}\/(?:dp|gp\/product))\/([A-Z0-9]{10})/);
        return asinMatch ? asinMatch[1] : null;
      }).filter(Boolean); // Filter out null ASINs */
      itemIds = await resolveLinksAndExtractASINs(inputValue);
      console.log(itemIds);
      if (itemIds.length === 0) {
        setError('Invalid product link(s). Please enter valid Amazon product links.');
        return;
      }
    }

    try {
      setIsLoading(true);
      setError(null);

      const { foundProducts, missingProductIds } = await getExistingProductsFromFirestore(itemIds);

      if (missingProductIds.length === 0) {
        setApiResult(foundProducts);
        console.log("All products are already in Firestore.");
        return;
      }

      const payload = { itemIds: missingProductIds };
      const response = await axios.post('https://dealcraft-api.vercel.app/getitems', payload, {
        headers: { 'Content-Type': 'application/json' },
      });

      const items = response.data?.ItemsResult?.Items || [];
      let formattedItems = [];

      if (items.length > 0) {
        formattedItems = items.map((item) => ({
          asin: item.ASIN,
          title: item.ItemInfo.Title.DisplayValue,
          detailPageURL: item.DetailPageURL,
          imageUrl: item.Images.Primary.Large.URL,
          price: item.Offers.Listings[0].Price.Amount,
          savings: item.Offers.Listings[0].Price.Savings ? item.Offers.Listings[0].Price.Savings.DisplayAmount : 'No Discount',
          features: item.ItemInfo.Features.DisplayValues || [],
          category: item.ItemInfo?.Classifications?.ProductGroup?.DisplayValue || "Uncategorized",
          binding: item.ItemInfo?.Classifications?.Binding?.DisplayValue || "Uncategorized",
        }));

        await saveProductsToFirestore(formattedItems);
      }

      const allProducts = [...foundProducts, ...formattedItems];
      setApiResult(allProducts);

    } catch (err) {
      setError('Failed to fetch data. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      <div className="flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-4">API Testing Page</h1>

        {/* Toggle button for ASIN / Link selection */}
        {/* Toggle button for ASIN / Link selection */}
        <div className="flex items-center justify-center mb-4 space-x-4">
          <button
            onClick={handleToggle}
            className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${isAsinMode
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
          >
            ASIN
          </button>
          <button
            onClick={handleToggle}
            className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${!isAsinMode
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
          >
            Link
          </button>
        </div>


        {/* Input Box */}
        <textarea
          value={inputValue}
          onChange={handleInputChange}
          placeholder={isAsinMode ? "Enter item IDs separated by commas (e.g., B0D7M4M611, B0CWYXPS59)" : "Enter full product link(s) (e.g., https://www.amazon.in/dp/B0CWYXPS59)"}
          className="w-full max-w-lg p-1 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows={2}
        ></textarea>

        {/* Get Details Button */}
        <button
          onClick={handleGetDetails}
          disabled={isLoading}
          className={`px-4 py-2 font-semibold text-white rounded-lg ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
        >
          {isLoading ? 'Fetching...' : 'Get Details'}
        </button>

        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>

      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Product Cards" />
        <Tab label="Collage Creator" />
        <Tab label="Product Template" />

      </Tabs>

      {/* Tab Panels */}
      <Box sx={{ paddingTop: 2 }}>
        {tabIndex === 0 && (
          <>
            {/* Display API Result as Cards */}
            <Grid container spacing={4} justifyContent="center">
              {isLoading ? (
                <div className="flex justify-center w-full">
                  <CircularProgress />
                </div>
              ) : (
                apiResult.map((item, index) => (
                  <Grid item key={index}>
                    <ProductCard product={item} />
                  </Grid>
                ))
              )}
            </Grid>
          </>
        )}

        {tabIndex === 1 && (
          <>
            {/* Collage Creator */}
            <CollageCreator products={apiResult} />
          </>
        )}
        {tabIndex === 2 && (
          <>
            {/* Product Template */}
            <ApiProductTemplate products={apiResult} />
          </>
        )}
      </Box>

    </div >
  );
};

export default ApiTestingPage;
