import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import html2canvas from "html2canvas";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import { isUserLoggedIn } from "../../utils/authUtils";
import { resolveLinksAndExtractASINs } from "../../utils/ApiHelper";
import ProductPost from "../../components/ProductPost";

const randomEmoji = () => {
    const emojis = ["✨", "🔥", "🌟", "💥", "🚀", "🎉"];
    return emojis[Math.floor(Math.random() * emojis.length)];
};

const CollagePage = () => {
    const [isAsinMode, setIsAsinMode] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const [productImages, setProductImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const collageRef = useRef();
    const [isUserLoggedInState, setIsUserLoggedInState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [postType, setPostType] = useState("Review");
    const [productName, setProductName] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [product, setProduct] = useState({});

    useEffect(() => {
        if (!isUserLoggedIn()) {
            setIsUserLoggedInState(false);
            return;
        }
    }, []);

    const handleToggle = () => {
        setIsAsinMode((prev) => !prev);
        setInputValue("");
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const fetchProductDetails = async () => {
        if (!inputValue.trim()) return;

        let itemIds = [];
        setIsLoading(true);
        try {
            if (isAsinMode) {
                itemIds = inputValue.split(",").map((id) => id.trim());
            } else {
                itemIds = await resolveLinksAndExtractASINs(inputValue);
            }

            if (itemIds.length === 0) {
                throw new Error("Invalid product links or IDs.");
            }

            const payload = { itemIds };
            const response = await axios.post("https://dealcraft-api.vercel.app/makepost", payload, {
                headers: { "Content-Type": "application/json" },
            });

            const product = response.data.ItemsResult.Items[0];
            const images = [
                product.Images.Primary.Large.URL,
                ...product.Images.Variants.map((v) => v.Large.URL),
            ];

            setProductImages(images);
            setSelectedImages(images.slice(0, 9));
            setProduct(product);
            // setProductName(product.ItemInfo?.Title?.DisplayValue || "Name Not Found");
            // setProductPrice(product.Offers?.Listings?.[0]?.Price?.Amount || 0);
            // console.log("name", productName, "price", productPrice);
        } catch (error) {
            console.error("Error fetching product details:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const createCollage = async () => {
        if (collageRef.current) {
            const canvas = await html2canvas(collageRef.current, { useCORS: true });
            return canvas.toDataURL("image/png");
        }
    };

    const downloadCollage = async () => {
        const dataUrl = await createCollage();
        if (dataUrl) {
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = "dealskraft_Collage.png";
            link.click();
        }
    };

    const copyCollage = async () => {
        const dataUrl = await createCollage();
        if (dataUrl) {
            const blob = await (await fetch(dataUrl)).blob();
            await navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
            setSnackbarOpen(true);
        }
    };

    const handleImageSelect = (imageUrl) => {
        if (selectedImages.includes(imageUrl)) {
            setSelectedImages(selectedImages.filter((img) => img !== imageUrl));
        } else if (selectedImages.length < 9) {
            setSelectedImages([...selectedImages, imageUrl]);
        }
    };

    return (
        <div className="p-6 bg-gray-50 min-h-screen flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-6 text-gray-700">Collage Creator</h2>

            {/* Toggle Input Mode */}
            {/* <div className="flex items-center justify-center mb-4 space-x-4">
                <button
                    onClick={handleToggle}
                    className={`px-4 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${
                        isAsinMode ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-600"
                    }`}
                >
                    {isAsinMode ? "Switch to Links" : "Switch to ASINs"}
                </button>
            </div> */}
            <div className="flex items-center justify-center mb-6 space-x-4">
                <button
                    onClick={handleToggle}
                    className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${
                        isAsinMode ? "bg-blue-600 text-white hover:bg-blue-700" : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                    }`}
                >
                    ASIN
                </button>
                <button
                    onClick={handleToggle}
                    className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${
                        !isAsinMode ? "bg-blue-600 text-white hover:bg-blue-700" : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                    }`}
                >
                    Link
                </button>
            </div>
            <textarea
                value={inputValue}
                onChange={handleInputChange}
                placeholder={
                    isAsinMode
                        ? "Enter item IDs separated by commas (e.g., B0D7M4M611)"
                        : "Enter full product link(s) (e.g., https://www.amazon.in/dp/B0CWYXPS59)"
                }
                className="w-full max-w-lg p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows={3}
            ></textarea>

            <button
                onClick={fetchProductDetails}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 mb-6 flex items-center justify-center"
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={20} className="mr-2" /> : "Fetch Product"}
            </button>

            <ProductPost product={product} />

            {/* Collage Section */}
            {selectedImages.length > 0 && (
                    <div className="flex justify-center space-x-6 mt-6">
                        <button
                            onClick={downloadCollage}
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        >
                            Download Collage
                        </button>
                        <button
                            onClick={copyCollage}
                            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                        >
                            Copy Collage
                        </button>
                    </div>
                )}
                <div className="grid grid-cols-9 gap-4 mb-6">
                    {productImages.map((url, index) => (
                        <div
                            key={index}
                            className={`p-1 border rounded-lg cursor-pointer transition-transform transform ${selectedImages.includes(url) ? "border-blue-500 scale-105" : "border-gray-300"
                                }`}
                            onClick={() => handleImageSelect(url)}
                        >
                            <img src={url} alt={`Product Image ${index + 1}`} className="w-10 h-10 object-cover rounded-lg" />
                        </div>
                    ))}
                </div>
                <div ref={collageRef} className="grid grid-cols-3 gap-1 bg-gray-100 p-4 rounded-lg shadow-lg">
                    {selectedImages.map((imageUrl, index) => (
                        <img
                            key={index}
                            src={imageUrl}
                            alt={`Collage Image ${index + 1}`}
                            className="w-full h-full object-contain rounded-lg"
                        />
                    ))}
                </div>


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                    Content copied to clipboard!
                </Alert>
            </Snackbar>
        </div>
    );
};

export default CollagePage;
