import React, { useState } from 'react';
import { TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';

const UserAddTemplate = () => {
  // State for template name, selected features, and layout type
  const [templateName, setTemplateName] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [layout, setLayout] = useState('standard');
  const [previewTemplate, setPreviewTemplate] = useState(null);

  // Available features
  const featuresOptions = [
    'Price',
    'Image',
    'Title',
    'Discount',
    'Rating'
  ];

  // Handle change in feature selection
  const handleFeatureChange = (event) => {
    const value = event.target.name;
    setSelectedFeatures((prevFeatures) => {
      if (prevFeatures.includes(value)) {
        return prevFeatures.filter(feature => feature !== value);
      } else {
        return [...prevFeatures, value];
      }
    });
  };

  // Handle template preview
  const handlePreview = () => {
    const template = {
      name: templateName,
      layout,
      features: selectedFeatures
    };
    setPreviewTemplate(template);
  };

  // Handle saving the template
  const handleSaveTemplate = () => {
    // Here you can save the template (e.g., to Firestore or local storage)
    console.log('Template Saved:', { templateName, selectedFeatures, layout });
    alert('Template Saved!');
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Create Your Own Template
      </Typography>

      {/* Template Name Input */}
      <TextField
        label="Template Name"
        variant="outlined"
        fullWidth
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        margin="normal"
      />

      {/* Layout Selection */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Layout Style</InputLabel>
        <Select
          value={layout}
          onChange={(e) => setLayout(e.target.value)}
          label="Layout Style"
        >
          <MenuItem value="standard">Standard</MenuItem>
          <MenuItem value="compact">Compact</MenuItem>
        </Select>
      </FormControl>

      {/* Feature Selection */}
      <Typography variant="h6" gutterBottom>
        Select Features to Include:
      </Typography>
      <FormGroup>
        {featuresOptions.map((feature) => (
          <FormControlLabel
            key={feature}
            control={<Checkbox checked={selectedFeatures.includes(feature)} onChange={handleFeatureChange} name={feature} />}
            label={feature}
          />
        ))}
      </FormGroup>

      {/* Preview Button */}
      <Button variant="contained" color="primary" onClick={handlePreview} style={{ marginTop: '20px' }}>
        Preview Template
      </Button>

      {/* Save Button */}
      <Button variant="contained" color="secondary" onClick={handleSaveTemplate} style={{ marginTop: '10px' }}>
        Save Template
      </Button>

      {/* Template Preview */}
      {previewTemplate && (
        <div style={{ marginTop: '30px', padding: '10px', border: '1px solid #ccc' }}>
          <Typography variant="h5">Preview: {previewTemplate.name}</Typography>
          <Typography variant="body1"><strong>Layout:</strong> {previewTemplate.layout}</Typography>
          <Typography variant="body1"><strong>Features:</strong> {previewTemplate.features.join(', ')}</Typography>
        </div>
      )}
    </div>
  );
};

export default UserAddTemplate;
