import React from 'react';

const ProfilePage = () => {
  return (
    <div className="flex flex-col md:flex-row p-6 bg-gray-100 min-h-full">
      {/* Sidebar */}
      <div className="w-full md:w-1/3 lg:w-1/4 bg-white p-6 shadow-md rounded-md">
        {/* Profile Header */}
        <div className="text-center">
          <img
            src="https://via.placeholder.com/150"
            alt="Profile"
            className="w-24 h-24 rounded-full mx-auto mb-4"
          />
          <h1 className="text-lg font-semibold">Nora Tsunoda</h1>
          <p className="text-gray-500">Security Lead</p>
        </div>

        {/* Menu */}
        <div className="mt-8">
          <ul className="space-y-4">
            <li className="text-blue-500 font-semibold cursor-pointer">Profile</li>
            <li className="text-gray-500 cursor-pointer">Tasks</li>
            <li className="text-gray-500 cursor-pointer">Calendar</li>
            <li className="text-gray-500 cursor-pointer">Files</li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6">
        {/* Section 1 */}
        <div className="bg-white p-6 shadow-md rounded-md mb-6">
          <h2 className="text-lg font-semibold mb-4">Nora spends most of their time on...</h2>
          <ul className="space-y-4">
            <li className="flex items-center space-x-4">
              <span className="w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center">
                <i className="fas fa-cogs"></i>
              </span>
              <span>Product Infrastructure</span>
            </li>
            <li className="flex items-center space-x-4">
              <span className="w-6 h-6 bg-yellow-500 text-white rounded-full flex items-center justify-center">
                <i className="fas fa-lock"></i>
              </span>
              <span>Network Security</span>
            </li>
            <li className="flex items-center space-x-4">
              <span className="w-6 h-6 bg-green-500 text-white rounded-full flex items-center justify-center">
                <i className="fas fa-shield-alt"></i>
              </span>
              <span>Security Testing</span>
            </li>
            <li className="flex items-center space-x-4">
              <span className="w-6 h-6 bg-purple-500 text-white rounded-full flex items-center justify-center">
                <i className="fas fa-globe"></i>
              </span>
              <span>Security Audit Outsourcing</span>
            </li>
            <li className="flex items-center space-x-4">
              <span className="w-6 h-6 bg-red-500 text-white rounded-full flex items-center justify-center">
                <i className="fas fa-bug"></i>
              </span>
              <span>Bugs</span>
            </li>
          </ul>
        </div>

        {/* Section 2 */}
        <div className="bg-white p-6 shadow-md rounded-md">
          <h2 className="text-lg font-semibold mb-4">Works most with...</h2>
          <div className="flex space-x-4">
            <img
              src="https://via.placeholder.com/50"
              alt="User"
              className="w-12 h-12 rounded-full"
            />
            <img
              src="https://via.placeholder.com/50"
              alt="User"
              className="w-12 h-12 rounded-full"
            />
            <img
              src="https://via.placeholder.com/50"
              alt="User"
              className="w-12 h-12 rounded-full"
            />
            <img
              src="https://via.placeholder.com/50"
              alt="User"
              className="w-12 h-12 rounded-full"
            />
            <img
              src="https://via.placeholder.com/50"
              alt="User"
              className="w-12 h-12 rounded-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
