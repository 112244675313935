import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../components/firebase';
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { Typography, Button, Box, Avatar, Container, Divider, Snackbar, Alert, CircularProgress } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FeatureRequestForm from '../components/FeatureRequestForm';
import FeedbackForm from '../components/FeedbackFrom';
import EditPassword from '../components/HelperUser/EditPassword';
import { isUserLoggedIn } from '../utils/authUtils';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the MUI Delete Icon
import TermsAndConditionsPopup from '../components/TermsAndConditionsPopup';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection

const UserDashboard = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' by default
  const [isUserLoggedInState, setIsUserLoggedInState] = useState(null); // State to track user login status
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      // User is not logged in or session expired
      setIsUserLoggedInState(false);
      return;
    }
    document.title = "User Dashboard";
    // User is logged in, proceed to fetch user data
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUserId(storedUser?.uid || null);
    setIsUserLoggedInState(true);

    // Fetch the user's display name from Firestore
    if (storedUser?.uid) {
      const fetchUserName = async () => {
        try {
          const userRef = doc(db, 'users', storedUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const data = userSnap.data();
            setUserName(data.name || ''); // Set display name from Firestore
          } else {
            setUserName(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserName();
    }
  }, []);

  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    try {
      await addDoc(collection(db, 'user-delete-request'), {
        userId,
        requestDate: new Date(),
      });
      setSnackbarMessage('Your account deletion request has been sent.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting delete request:', error);
      setSnackbarMessage('An error occurred while submitting your request.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeleteLoading(false);
    }
  };

  // Redirect to login if the user is not logged in
  if (isUserLoggedInState === false) {
    return <Navigate to="/user-login" replace />;
  }

  if (isUserLoggedInState === null) {
    // Avoid rendering anything until the login status is confirmed
    return null;
  }

  // Navigate to template page
  const handleGoToTemplate = () => {
    navigate('/templategenerator');
  };

  // Navigate to brands page
  const handleGoToBrands = () => {
    navigate('/user/brands');
  };

  return (
    <Container component="main" maxWidth="md" sx={{ textAlign: 'center' }}>
      <TermsAndConditionsPopup /> {/* Assuming this component exists */}

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
        <Avatar sx={{ width: 100, height: 100, mb: 2, bgcolor: 'primary.main' }}>
          <AccountCircleIcon sx={{ fontSize: 80, color: 'white' }} />
        </Avatar>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          {userName ? `Hello, ${userName}` : 'Hi there!'}
        </Typography>

        <Divider variant="middle" sx={{ width: '100%', mb: 0 }} />

        <Box sx={{ width: '100%', mt: 1 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Dashboard Options</Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mb: 0 }}>
            {/* Edit Password Component */}
            <EditPassword />
            <div className='mt-4'>
              <button
                onClick={handleDeleteRequest}
                className="px-4 py-2 bg-red-600 text-white rounded-md flex items-center justify-center gap-2"
                disabled={deleteLoading} // Disable button while loading
              >
                {deleteLoading ? (
                  // Loader Spinner
                  <div className="w-5 h-5 border-4 border-t-4 border-white border-opacity-50 rounded-full animate-spin"></div>
                ) : (
                  // Delete Icon
                  <DeleteIcon className="mr-2" />
                )}
                <span className="text-sm">{deleteLoading ? 'Deleting...' : 'Delete'}</span>
              </button>
            </div>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Box>
              <FeedbackForm userId={userId} />
            </Box>
            <Box>
              <FeatureRequestForm userId={userId} />
            </Box>
          </Box>

          {/* Buttons for navigating to template and brands */}
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoToTemplate}
              sx={{ px: 4 }}
            >
              Go to Template Generator
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGoToBrands}
              sx={{ px: 4 }}
            >
              Show Brands
            </Button>
          </Box>

        </Box>
      </Box>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserDashboard;
