import { getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../components/firebase';

// Function to add or update product in category
const addOrUpdateProductInCategory = async (category, productId) => {
  const categoryRef = doc(db, 'categories', category);  // Reference to category document

  try {
    // Check if the category document exists
    const categoryDoc = await getDoc(categoryRef);

    if (categoryDoc.exists()) {
      // Category exists, check if the product ID is already present
      await updateDoc(categoryRef, {
        products: arrayUnion(productId), // Append the product ID to the 'products' array
      });
      console.log('Product appended to existing category.');
    } else {
      // Category does not exist, create a new category with the product ID
      await setDoc(categoryRef, {
        products: [productId], // Create a new array with the product ID
      });
      console.log('New category created and product added.');
    }
  } catch (error) {
    console.error('Error adding or updating product in category:', error);
  }
};

const saveProductsToFirestore = async (products) => {
    try {
      for (const product of products) {
        const productDocRef = doc(db, "products", product.asin);
        await setDoc(productDocRef, product, { merge: true });
      }
      console.log("Products saved to Firestore!");
    } catch (error) {
      console.error("Error saving products to Firestore:", error);
    }
  };


export { addOrUpdateProductInCategory, saveProductsToFirestore };