import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Divider } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { useAuth } from './AuthContext'; // Import the useAuth hook

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  // Get auth context values
  const { isUserLoggedIn, isAdminLoggedIn, logout } = useAuth();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    logout();
    navigate('/user-login'); // Redirect to user login page
  };

  const handleGoToDashboard = () => {
    if (isAdminLoggedIn) {
      navigate('/admin-dashboard');
    } else if (isUserLoggedIn) {
      navigate('/user/dashboard');
    }
  };

  // Helper function to check if a path is active
  const isActive = (path) => (location.pathname === path ? 'bg-blue-500 text-white' : 'text-black');

  return (
    <AppBar position="sticky" sx={{ width: '100%', margin: 0, top: 0 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" component={Link} to="/" sx={{ marginRight: 2 }}>
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}>
            DealsKraft
          </Typography>
        </Box>

        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={{ fontWeight: 'bold', ...isActive('/') }}
            >
              Home
            </Button>

            {/* Conditionally Render Template button when user is logged in */}
            {isUserLoggedIn || isAdminLoggedIn ? (
              <>
                <Button
                  color="inherit"
                  onClick={handleGoToDashboard}
                  sx={{ fontWeight: 'bold', ...isActive('/user/dashboard') || isActive('/admin-dashboard') }}
                >
                  Dashboard
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/templategenerator"
                  sx={{ fontWeight: 'bold', ...isActive('/templategenerator') }}
                >
                  Template
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/user/getitems"
                  sx={{ fontWeight: 'bold', ...isActive('/user/getitems') }}
                >
                  Create Deals
                </Button>
              </>
            ) : null}

            <Button
              color="inherit"
              component={Link}
              to="/about-us"
              sx={{ fontWeight: 'bold', ...isActive('/about-us') }}
            >
              About Us
            </Button>

            {/* Conditionally render Login and Sign Up when not logged in */}
            {!isUserLoggedIn && !isAdminLoggedIn && (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/user-login"
                  sx={{ fontWeight: 'bold', ...isActive('/user-login') }}
                >
                  Login
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/signup"
                  sx={{ fontWeight: 'bold', ...isActive('/signup') }}
                >
                  Sign Up
                </Button>
              </>
            )}

            {/* Conditionally render Dashboard and Logout buttons when logged in */}
            {(isUserLoggedIn || isAdminLoggedIn) && (
              <>

                <Button
                  color="inherit"
                  onClick={handleLogout}
                  sx={{ fontWeight: 'bold' }}
                >
                  Logout
                </Button>
              </>
            )}
          </Box>
        )}

        {isMobile && (
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        )}

        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}>
          <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
            <List>
              <ListItem button component={Link} to="/" className={isActive('/')}>
                <ListItemText primary="Home" />
              </ListItem>

              {/* Conditionally render Template link in mobile view when user is logged in */}
              {(isUserLoggedIn || isAdminLoggedIn) && (
                <>
                  <ListItem button onClick={handleGoToDashboard} className={isActive('/user/dashboard') || isActive('/admin-dashboard')}>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  <ListItem button component={Link} to="/templategenerator" className={isActive('/templategenerator')}>
                    <ListItemText primary="Template" />
                  </ListItem>
                  <ListItem button component={Link} to="/user/getitems" className={isActive('/user/getitems')}>
                    <ListItemText primary="Create Deals" />
                  </ListItem>
                </>
              )}

              <ListItem button component={Link} to="/about-us" className={isActive('/about-us')}>
                <ListItemText primary="About Us" />
              </ListItem>

              <Divider />

              {/* Conditionally render Login and Sign Up in mobile view */}
              {!isUserLoggedIn && !isAdminLoggedIn && (
                <>
                  <ListItem button component={Link} to="/user-login" className={isActive('/user-login')}>
                    <ListItemText primary="Login" />
                  </ListItem>
                  <ListItem button component={Link} to="/signup" className={isActive('/signup')}>
                    <ListItemText primary="Sign Up" />
                  </ListItem>
                </>
              )}

              {/* Conditionally render Dashboard and Logout in mobile view when logged in */}
              {(isUserLoggedIn || isAdminLoggedIn) && (
                <>

                  <ListItem button onClick={handleLogout}>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
